import PurchaseProvider, { useStartPurchase, useErrorTryAgain, useLoading, useJwt } from '../common/context/PurchaseContext';
import { useEffect, useMemo, useState } from 'react';
import { ReactComponent as Plant } from '../icons/reviews/leafy-plant.svg';
import { provide, useAsyncEffect } from '@hilma/tools';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, CircularProgress } from '@material-ui/core';
import { useUser101, User } from '../common/context/User101Context'
import { useIsPortrait } from '../common/context/IsPortraitContext';
import queryString from 'query-string';
import { useI18n } from "../translation/i18n";
import { useAlert } from '@hilma/forms';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Shapes } from "../components/Shapes";
import { useOn } from '@hilma/socket.io-react';
import axios from 'axios';
import '../style/Payment.scss'

type messagesTypes = 'close' | 'payment-success' | 'payment-error' | 'fail-load' | null;

const USER_PROPERTIES: Partial<keyof User>[] = ['name', 'organization_name', 'username', 'phone']

interface PurchaseInfo {
	info: {
		domain?: string,
		cost?: number,
		projectName?: string,
		number_of_licenses?: number,
		years_to_expire?: number
	},
	restrictions: {
		enName?: string,
		heName?: string,
		enText?: string,
		heText?: string
	}
}

const Payment: React.FC = () => {
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [messageType, setMessageType] = useState<messagesTypes>(null);
	const [purchaseData, setPurchaseData] = useState<PurchaseInfo>({ info: {}, restrictions: {} });

	const startPurchase = useStartPurchase();
	const errorTryAgain = useErrorTryAgain();
	const loading = useLoading();
	const jwt = useJwt();

	const { id: purchaseTypeId } = useParams<"id">();
	const { search } = useLocation()
	const navigate = useNavigate();
	const payment = useI18n(i18n => i18n.payment);
	const errors = useI18n(i => i.errors);
	const classes = useStyles();
	const alert = useAlert();
	const isPortrait = useIsPortrait();
	const { user } = useUser101();

	useEffect(() => {
		window.addEventListener('message', meshulamMessage);

		return () => {
			window.removeEventListener('message', meshulamMessage);
		}
	}, []);

	useEffect(() => {
		const message = (queryString.parse(search))?.["message"];
		if (message === "success") setMessageType('payment-success');
		else if (message === "error") setMessageType('payment-error');
		else if (message === "cancel") setMessageType('close');
	}, [search])

	useAsyncEffect(async () => {
		await getPurchaseData();
	}, [])

	const meshulamMessage = (result: MessageEvent<any>) => {
		if (result?.origin === 'https://meshulam.co.il' || result?.origin === 'https://sandbox.meshulam.co.il') {
			console.log('result?.data: ', result?.data);
			switch (result?.data?.action) {
				case 'close': {
					console.log('in close meshulam');
					setMessageType('close');
					break;
					// cancel
				}
				case 'payment': {
					if (result?.data?.status == 1) {
						console.log('in payment 1');
						setMessageType('payment-success');
						// success
					} else if (result?.data?.status === 0) {
						console.log('in payment 2');
						setMessageType('payment-error');
						// error
					}
					break;
				}
				case 'failed_to_load_page': {
					console.log('from failed to load');
					// todo- send request to close the purchase
					setMessageType('fail-load');
					break;
				}
				default:
					console.log('default');
					setMessageType(null);
					break
			}
		}
	}

	const getPurchaseData = async () => {
		try {
			const { data } = await axios.get<PurchaseInfo>(`/api/purchase-type/get-product-purchase-data/${purchaseTypeId}`)
			setPurchaseData(data);
		} catch (err) {
			console.log('err: ', err);
			alert("ארעה שגיאה בהבאת המידע. אנא נסו לרענן את העמוד")
		}
	}

	const onErrorTryAgainClick = async () => {
		setErrorMessage(null);
		try {
			if (typeof errorTryAgain !== "function") {
				alert("בעיה ץפעולית באתר, אנא דווחו על כך למנהלי האתר")
			}
			await errorTryAgain?.();
			setMessageType(null);
		} catch (err) {
			alert(errors.generalError);
			return;
		}
	}

	const onStartPurchaseClick = async () => {
		if (loading || !!jwt) return;
		startPurchase?.();
	}

	useOn('meshulam-cancel', () => {		
		setMessageType('close');
	});

	useOn('meshulam-error', (errorMessage: string) => {
		setMessageType('payment-error');
		setErrorMessage(errorMessage || payment.pem);
	});

	useOn('meshulam-good', async () => {
		setMessageType('payment-success');
	});

	const messagesDisplay = useMemo(() => {
		let title: string | undefined = "";
		let subTitle: string | undefined = "";
		let buttons: { id: number, text: string, click: (() => any), className?: string }[] = [];
		switch (messageType) {
			case 'payment-error':
				title = "ארעה שגיאה בעת ביצוע הרכישה";
				subTitle = "";
				buttons = [
					{ text: "נסו שנית", click: onErrorTryAgainClick, id: 1 },
				]
				break;
			case 'payment-success':
				title = "הרכישה בוצעה בהצלחה!";
				subTitle = "בדקות הקרובות ישלח אליך דואר אלקטרוני עם פרטי הרשיון";
				buttons = [
					{
						click: () => { window.open(purchaseData.info.domain, "_blank") },
						text: `כניסה ל${purchaseData?.restrictions?.heName || ""}`,
						className: "orange-button",
						id: 1,
					}
				]
				break;
			case 'close':
				title = "ביטול הרכישה בוצע בהצלחה";
				subTitle = "";
				buttons = [
					{ text: "בחזרה לעמוד הראשי", click: () => { navigate('/') }, id: 1 },
				]
				break;
			case 'fail-load':
				title = "ארעה שגיאה בטעינת הרכישה ובקשתכם בוטלה";
				subTitle = "";
				buttons = [
					{ text: "בחזרה לעמוד הראשי", click: () => { navigate('/') }, id: 1 },
				]
				break;
			default:
				break;
		}
		return <div className="payment-messages">
			<div className='content'>
				<h2>{title}</h2>
				{subTitle ? <h4>{subTitle}</h4> : null}
				<div className="buttons">
					{buttons.map(b => <Button disabled={loading} className={b.className || "general"} onClick={b.click} key={b.id} >{b.text}</Button>)}
				</div>
			</div>
		</div>
	}, [messageType, purchaseData]);

	if (!errorMessage || true) return (
		<div className={classes.container}>
			<div className="header">
				<div className='background'></div>
				<div className="text">
					<div className="product-name">
						{purchaseData?.restrictions?.heName || "Project Name"}
					</div>
					<span className="subtitle">רכישת רשיון ארגון קטן</span>
				</div>
				<Plant className="plant" />
				<Shapes className='rect medium blue-gray' bottom="5%" right="40vw" top="initial" />
				<Shapes className='rect medium blue-gray' top="40%" right="3vw" />
				<Shapes className='rect medium yellow index-h lemon' left="30vw" right="initial" bottom={isPortrait ? "55%" : "50%"} top='initial' />
				<Shapes className='rect medium yellow index-h lemon' top="0" right="15vw" />
				<Shapes className='circle transform small blue' top='5%' left="15vw" />
				<Shapes className='circle transform large yellow opacity-m' top={isPortrait ? "25%" : "50%"} left="2vw" />
			</div>

			{messageType ? <div className="messages-container">{messagesDisplay}</div>
				: <div className="purchase-data">
					<div className="info">
						<div className="title">פרטי משתמש</div>
						<div className="user-data">
							{USER_PROPERTIES.map((ent) => <div key={ent} className="data">
								<label htmlFor={ent}>{payment[ent as keyof typeof payment]}</label>
								<input id={ent} disabled={true} value={String(user?.[ent]) || ""} type="text" />
							</div>)}
						</div>
					</div>
					<div className="info">
						<div className="title">פרטי רכישה</div>
						<div className="general-info">
							<br />
							{Object.entries(purchaseData.info).map((en) => en[0] !== "domain" ? <div key={en[0] || ""} className="data">
								<label htmlFor={en[0]}>{payment[en[0] as keyof typeof payment]}</label>
								<input id={en[0]} disabled={true} value={en[1]} />
							</div> : null)}
						</div>
						<div className="restrictions">
							<strong>הרשאות:</strong>
							<br />
							<div>
								{purchaseData.restrictions.heText}
							</div>
						</div>
					</div>
					<div className="buttons">
						<Button variant="outlined" disabled={loading || !!jwt} className="move-to-purchase" onClick={onStartPurchaseClick} >
							<div className="button-content">
								{loading ? <CircularProgress style={{ color: "white", height: "2rem", width: "2rem", margin: "auto" }} /> : "רכישה"}
							</div>
						</Button>
					</div>
				</div>}
		</div>
	);
}

export default provide(PurchaseProvider)(Payment);

const useStyles = makeStyles(theme => ({
	container: {
		overflowX: 'hidden',
		height: '100%',
		width: "100vw",
		"&::-webkit-scrollbar": {
			display: "none"
		}
	},

	iframe: {
		width: '100%',
		height: "100%",
		border: 'none',
	},

	mobileIframe: {
		height: 720,
	},

	hidden: {
		display: 'none'
	},

	errorContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		alignItems: 'center',
	},

	sadFace: {
		height: '127px !important',
		width: '127px !important',
		marginBottom: 23,
		[theme.breakpoints.up(1600)]: {
			height: '172px !important',
			width: '172px !important',
			marginBottom: 32
		},
	},

	ohNo: {
		fontSize: 35,
		marginBottom: 8,
		fontFamily: 'Assistant-SemiBold',
		color: theme.palette.primary.main,
		[theme.breakpoints.up(1600)]: {
			fontSize: 50,
			marginBottom: 11
		},
	},

	errorMessage: {
		fontSize: 22,
		marginBottom: 82,
		fontFamily: 'Assistant-SemiBold',
		color: theme.palette.primary.main,
		[theme.breakpoints.up(1600)]: {
			fontSize: 30,
			marginBottom: 111
		},
	},

	tryAgain: {
		width: '192px !important',
		height: '46px !important',
		[theme.breakpoints.up(1600)]: {
			width: '260px !important',
			height: '63px !important',
		},
	},

	tryAgainLabel: {
		fontSize: 22,
		[theme.breakpoints.up(1600)]: {
			fontSize: 30,
		},
	}
}));
