import { createI18nText } from "../createTextFunctions";

export const PrivateAreaTexts = createI18nText({
    he: {
       projectName: "שם הפרויקט",
       expireDate: "תאריך התפוגה",
       licenseType: "סוג הרשיון",
       code: "קוד הרשיון",
       licenseName: "שם הרשיון",
       licensesNumber: "מספר הרשיונות",
       refund: "קבלת החזר כספי",
       title: "מידע על הרשיון",
       redirectToSite: "מעבר לאתר הפרויקט",
       moreInfo: "מידע נוסף",
       completePurchase: "השלמת תהליך רכישה",
       expired: "פג תוקף",
       daysLeft: "ימים נשארו",
       institutional: "ארגוני",
       private: "פרטי",
       customized: "מוסדי",
       adminSystem: "כניסה למערכות הניהול",
       noData: "עוד אין רכישות של פתרונות",
       cantTransport: "לא ניתן לעבור לאתר",
       dataError: 'ארעה שגיאה בהבאת המידע',
       NoRemoteUserId: "אין רישיון בפרויקט המשויך למשתמש זה",
       NoDomainForThisProject: "אין אפשרות לגשת לפרויקט זה, אנא פנו לתמיכה הטכנית",
       active: "מספר רשיונות פעילים"
    },
    en: {
       projectName: "Project name",
       expireDate: "Expire date",
       licenseType: "License type",
       code: "License's code",
       licenseName: "License name",
       licensesNumber: "Number of licenses",
       refund: "Refund purchase",
       title: "Information about license",
       redirectToSite: "Open project's site",
       moreInfo: "More info",
       completePurchase: "Complete purchase process",
       expired: "Expired",
       daysLeft: "more days left",
       institutional: "Institutional",
       private: "Private",
       customized: "Customized",
       adminSystem: "Enter administration systems",
       noData: "There are no purchases of solutions yet",
       cantTransport: "לא ניתן לעבור לאתר",
       dataError: "An error accured. Please try again later",
       NoRemoteUserId: "There is no license in the project associated with this user",
       NoDomainForThisProject: "You can't access this project, please contact technical support",
       active: "Active licenses"
    }
})