import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { FormInput, FormInputProps } from '@hilma/forms';

export const FormPasswordInput: FC<FormInputProps> = ({ ...rest }) => {
    const [showPass, setShowPass] = useState<boolean>(false);

    return (
        <FormInput
            autoComplete="on"
            endAdornment={(
                <>
                    <IconButton onClick={() => { setShowPass(prev => !prev) }}>
                        {showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                </>

            )}
            type={showPass ? "text" : 'password'}
            {...rest} />
    )
}
