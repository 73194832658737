import { ReactComponent as Plant } from './../icons/plant.svg';
import { useDirection, useI18n } from '../translation/i18n';
import { Button, ButtonBase } from '@material-ui/core';
import { useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ReactComponent as Cancel } from '../icons/cancel_blue.svg'
import '../style/fullPagePopup.scss';

interface PopupProps {
    isOpen: boolean;
    imagePath?: string;
    title: string;
    fullScreen?: boolean;
    text?: string;
    button?: { text: string; onClick: Function };
    onClose?: () => void
}

const GenericPopup: React.FC<PopupProps> = ({ isOpen, title, text = '', imagePath, button, onClose, fullScreen, children }) => {
    const direction = useDirection();
    const alts = /* useI18n(i18n => i18n.alts) */{} as any;
    const texts = useI18n(i => i.profile);

    const imagePathsAndAlts = useRef({
        '/icons/error.png': alts.addHeart,
        '/icons/success.png': alts.envelope
    })

    return (
        <Dialog fullScreen={fullScreen} transitionDuration={0} id='material-popup' open={isOpen} onClose={onClose} >
            <div id='popup' style={{ direction }}>
                <div className="x-button"><Cancel onClick={onClose}/></div>
                {imagePath ? <div className='icon' >
                    <img height='100%' width='100%' src={imagePath} alt={imagePathsAndAlts.current[imagePath as keyof typeof imagePathsAndAlts.current]} />
                </div> : null}
                <div className='title' >{title}</div>
                <div className='text' >{text}</div>
                {children}
                <div className="buttons">
                    {button ? <Button variant='outlined' onClick={() => { if (typeof button.onClick === 'function') button.onClick() }}>{button.text}</Button> : null}
                    {onClose ?
                        <Button variant='outlined' onClick={onClose}>
                            {texts.close}
                        </Button> : null}
                </div>
                <Plant className="plant-img" style={{ left: direction === 'ltr' ? "" : '0', right: direction === 'ltr' ? "0" : "" }} />
            </div>
        </Dialog>
    );
}

export default GenericPopup;