import { Localize, useDirection, useI18n, useTransformObject } from "../translation/i18n";
import React, { useRef, useState, useEffect } from "react";
import { OrganizationPrucahseButton } from "../components/product-page/OrganizationPrucahseButton";
import { ReactComponent as Plant } from '../icons/plant.svg';
import { ReactComponent as Left } from '../icons/left.svg';
import { useNavigate, useParams } from "react-router-dom";
import { Product as ProductType } from "../common/types/Product.type";
import { Swiper, SwiperSlide } from 'swiper/react';
import { UpcomingTutorials } from "../components/product-page/UpcomingTutorials";
import { ProductInfoStrip } from "../components/product-page/ProductInfoStrip";
import { CircularProgress } from "@material-ui/core";
import { ProductFeatures } from "../components/product-page/ProductFeatures";
import { useAsyncEffect } from "@hilma/tools";
import { DownloadButton } from "../components/product-page/DownloadButton";
import { Recommenders } from "../components/product-page/Recommenders";
import { Navigation } from "swiper";
import { useAlert } from "@hilma/forms";
import { useMemo } from "react";
import { Video } from "../components/product-page/Video";
import axios from "axios";
import '../style/Product.scss';

export const Product: React.FC = () => {
    const [product, setProduct] = useState<ProductType>();
    const [loading, setLoading] = useState(true);

    const not_translated_product = useRef<Localize<ProductType>>()
    const params = useParams<"name">()
    const hilmaText = useI18n(i => i.product)
    const GalleryTitle = useI18n(i => i.product.gallery)
    const transform = useTransformObject();
    const navigate = useNavigate();
    const dir = useDirection();
    const errors = useI18n(i => i.errors);
    const alert = useAlert();

    useAsyncEffect(async () => {
        try {
            const res = await axios.get<Localize<ProductType>>('/api/product/get-product', { params: { name: params.name } });
            console.log('res: ', res.data);
            not_translated_product.current = res.data;
            setProduct(transform(res.data));
        } catch (err: any) {
            if (err?.data?.message === 'NoSuchProduct') {
                alert(errors.noProduct);
                navigate('/')
            } else alert(errors.bringData);
        }
        setLoading(false);
    }, [])

    useEffect(() => {
        setProduct(transform(not_translated_product.current));
    }, [transform])

    const renderStrips = useMemo(() => {
        return product?.order?.map((strip, index) => {
            switch (strip) {
                case "Features":
                    return <ProductFeatures features={product.productFeatures} key={index} />
                case "Gallery":
                    return <div className="gallery"
                        style={{ direction: dir, display: product?.imageGallery?.length ? "" : "none" }}
                        key={index}>
                        <div className="title">{GalleryTitle}</div>

                        <Swiper pagination={true} navigation={{
                            nextEl: dir === 'rtl' ? '.swiper-button-unique.next' : '.swiper-button-unique.prev',
                            prevEl: dir === 'rtl' ? '.swiper-button-unique.prev' : '.swiper-button-unique.next'
                        }}
                            modules={[Navigation]} slidesPerView={'auto'} spaceBetween={10} >
                            {product?.imageGallery?.map?.(src => <SwiperSlide style={{ width: 'fit-content', height: '100%', maxWidth: '50vw', overflow: 'hidden' }} key={src}>
                                <img draggable={false} alt="" src={src} />
                            </SwiperSlide>)}
                        </Swiper>
                        <div className="swiper-button-unique next"><Left /></div>
                        <div className="swiper-button-unique prev"><Left /></div>
                    </div>;
                case "OrganizationPurchase":
                    return <OrganizationPrucahseButton key="OrganizationPurchase" info={product.purchaseText} />
                case "Recommenders":
                    return <Recommenders key="Recommenders" recommenders={product.recommenders} />
                case "Tutorials":
                    return <UpcomingTutorials key="Tutorials" tutorials={product.tutorials} />
                case "Video":
                    return <Video key="Video" video={product.video} />
                default: return <></>
            }
        })
        // eslint-disable-next-line
    }, [product])

    return <div className={"product-container " + dir}>
        {loading ? <CircularProgress className='loading' /> : <>
            <div className="main-info">
                <ProductInfoStrip info={product?.infoStrip} color={product?.infoStrip?.titleColor} name={product?.name} />
            </div>
            {renderStrips}
        </>}
        <DownloadButton product={product} />
        <div className="bottom">
            <Plant className="plant" />
            <div className="center-text">
                <div className="by-hilma">{hilmaText.developedBy} {product?.thumbnail?.collaborateId?.name || product?.thumbnail?.collaborateId?.heName || hilmaText.hilma}</div>
                <img src={product?.thumbnail?.collaborateId?.image || "/icons/Hilma.svg"} alt="logo" className="hilma-logo" />
            </div>
        </div>
    </div>
}