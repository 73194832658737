interface RegexObj {
    [key: string]: {
        regex?: [RegExp, string],
        maxWidth?: [number, string],
        minWidth?: [number, string],
        empty?: [string]
    }
}

export const HEBREW_REGEX = /^[\w\s -' \u0590-\u05fe]+$/;
export const NAME_REGEX = /^[\w\s -' \u0590-\u05fe]+ [\w\s -' \u0590-\u05fe]+$/

export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]*$/;
export const PASSWORD_WITH_STARS = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]*$|^\*{9}$/;

export const REGEX: RegexObj = {
    uName: {
        regex: [/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "uNameRegex"],
        maxWidth: [100, "maxValue"],
        minWidth: [8, "minValue"],
        empty: ["empty"]
    },
    pWord: {
        regex: [PASSWORD_REGEX, "pWordRegex"],
        maxWidth: [50, "maxValue"],
        minWidth: [8, "minValue"],
        empty: ["empty"]
    },
    pWordVer: {
        regex: [/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*-_#?&])[A-Za-z\d@$!%*-_#?&]*$/, "pWordVerRegex"],
        maxWidth: [50, "maxValue"],
        minWidth: [8, "minValue"],
        empty: ["empty"]
    },
    orgName: {
        regex: [HEBREW_REGEX, "pWordVerRegex"],
        maxWidth: [100, "maxValue"],
        minWidth: [2, "minValue"],
        empty: ["empty"]
    },
    phone: {
        regex: [/^05\d([-]{0,1})\d{7}$/, "phoneRegex"],
        maxWidth: [16, "maxValue"],
        minWidth: [10, "minValue"],
        empty: ["empty"]
    },
    name: {
        regex: [HEBREW_REGEX, "nameRegex"],
        empty: ["empty"]
    },
    code: {
        regex: [/^[0-9]{8,8}$/, "codeRegex"],
        maxWidth: [8, "maxValue"],
        minWidth: [8, "minValue"],
        empty: ["empty"]
    }
}