import React, { useMemo } from "react"
import { ReactComponent as Zoom } from '../icons/camera.svg';
import { ReactComponent as Location } from '../icons/location.svg';
import { Tutorial as TutorialType } from "../common/types"
import { useI18n } from "../translation/i18n"
import { useParams } from "react-router-dom";

interface TutorialProps {
    tutorial: TutorialType
}
export const Tutorial: React.FC<TutorialProps> = ({ tutorial }) => {
    const { name } = useParams<"name">();
    const signupText = useI18n(i => i.product.signup)

    const date = useMemo(() => {
        if (!tutorial.date) return { date: '', time: '' };
        return { date: new Date(tutorial.date).toLocaleDateString(), time: tutorial.timeRange };
    }, [tutorial?.date])

    return <div className="tutorial">
        <div className="heading">
            <div className="tut-product-name">{name}</div>
            {tutorial.location ? <Location /> : <div className="camera"><Zoom /><div className="triangle" /></div>}
        </div>
        <div className="tut-title">{tutorial.title}</div>
        <div className="tut-subtitle">{tutorial.subtitle}</div>
        <div className="details-n-signup-btn">
            <div className="details">
                <p className="when" style={{ direction: 'ltr' }}>  {date.time} &nbsp;|&nbsp;{date.date}</p>
                <p className="where">{tutorial.location!!}</p>
            </div>
            <button className="sign-up" onClick={() => { window.open(tutorial.link, '_blank'); }}>
                {signupText}
            </button>

        </div>
    </div >
}