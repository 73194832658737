import { FormInput, FormProvider, useAlert, useForm, useUpdateOnSubmit } from '@hilma/forms';
import { useDirection, useI18n, useLanguage, useTranslate } from '../translation/i18n';
import React, { MouseEventHandler, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, CircularProgress } from '@material-ui/core';
import { PASSWORD_REGEX } from '../common/consts/regex.consts';
import { makeStyles } from '@material-ui/styles';
import { provide } from '@hilma/tools';
import * as Yup from 'yup';
import axios from 'axios';

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required({ path: 'error.required' })
    .matches(PASSWORD_REGEX, 'errors.pWordRegex'),

  confirmPassword: Yup.string()
    .required({ path: 'error.required' })
    .matches(PASSWORD_REGEX, { path: 'errors.pWordRegex' })
})

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  
  const dir = useDirection();
  const [search] = useSearchParams();
  const text = useI18n(i18n => i18n.login);
  const form = useForm()
  const save = useI18n(i18n => i18n.profile.save);
  const errors = useI18n(i18n => i18n.errors);
  const navigate = useNavigate()
  const alert = useAlert();
  const classes = useStyles();

  const goToHomePage = () => navigate('/');

  const sendResetPassword = async () => {
    setLoading(true);
    try {
      await axios.post('/api/admin/reset-password-user101', {
        ...form.values,
        username: search.get('email'),
        token: search.get('token')
      })
      alert(text.resetPasswordSuccess, 'success');
      goToHomePage();
    } catch (err: any) {
      alert(text[err?.data?.message as keyof typeof text] || errors.generalError)
    }
    setLoading(false)
  }

  const validatePasswordAndConfirm = () => {
    if (form.values.password !== form.values.confirmPassword) {
      form.setFieldError('password', errors.PasswordAndConfirmDoesntMatch);
      form.setFieldError('confirmPassword', errors.PasswordAndConfirmDoesntMatch);
      return false;
    }
    return true
  }

  useUpdateOnSubmit(() => {
    if (!validatePasswordAndConfirm()) return;
    (async () => {
      await sendResetPassword();
    })()
  })

  return (
    <div className={"reset-password-container " + dir}>
      <h2 className="title reset-password-title">{text.resetPassword}</h2>
      <FormInput classes={{ label: "label-x", container: "input-container", errorContainer: "error-msg" }} id="password" description={text.newPassword} />
      <FormInput classes={{ label: "label-x", container: "input-container", errorContainer: "error-msg" }} id="confirmPassword" description={text.confirmPassword} />
      <div className={"buttons " + classes.container}>
        <Button onClick={form.handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
          className={`send-btn ${classes.button} ${classes.noHover}`}
          children={!loading ? save :
            <CircularProgress size={'1.1rem'} thickness={5} />}
        />
        <Button onClick={goToHomePage} children={text.homePage} className={`${classes.home} ${classes.button}`} />
      </div>
    </div>
  )
}

export default provide([
  FormProvider, {
    initialValues: { password: '', confirmPassword: '' },
    rules: {},
    validationSchema: ResetPasswordSchema,
    customI18n: {
      usei18n: useI18n, useTranslate, useDirection, useLanguage: useLanguage as () => string
    }
  }
])(ResetPassword)


const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse'
  },
  home: {
    backgroundColor: 'white',
    border: '1px solid #1a365040',
    minWidth: 'fit-content'
  },
  button: {
    fontSize: '2rem',
    fontWeight: 700,
    height: 'calc(1.5rem + 1vh)',
    width: '7vw',
    borderRadius: '5px',
    padding: '0.5vh 1.3vw',
  },
  noHover: {
    '&:hover': {
      backgroundColor: '#1a3650',
    }
  }
})