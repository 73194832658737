import React, { createContext, useState, useContext, Dispatch, SetStateAction } from 'react';
import { useUser } from '@hilma/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { useI18n, usePathLanguage } from '../../translation/i18n';
import { useAsyncEffect } from '@hilma/tools';
import axios from 'axios';
import { useAlert } from '@hilma/forms';
import { useIsAuthenticated } from '@hilma/auth'
import { UserTypes } from '../consts/orgTypeOptions';

export interface User {
    "name": string,
    "username": string,
    "password"?: string,
    "phone"?: string,
    "isMissing": boolean,
    "organization_name"?: string,
    "user_type"?: UserTypes,
    "isGoogleUser"?: boolean
}

export const User101Context = createContext<{ user?: User, setUser: Dispatch<SetStateAction<User | undefined>> } | null>(null)

export const useUser101 = () => useContext(User101Context)!

export const User101Provider: React.FC<{ children?: React.ReactNode }> = ({ children }: { children?: React.ReactNode }) => {
    const [user, setUser] = useState<User>();
    const alert = useAlert()
    const i18n = useI18n(i => i.errors)
    const isAuthenticated = useIsAuthenticated()

    useAsyncEffect(async () => {
        try {
            if (!isAuthenticated) return;
            const { data } = await axios.get<User>("/api/101/user/get-user");
            setUser(data)
        } catch {
            alert(i18n.generalError)
        }
    }, [isAuthenticated]);

    return (
        <User101Context.Provider value={{ user, setUser }}>
            {children}
        </User101Context.Provider>
    )
}
