import { createContext, FC, useContext, useEffect } from 'react'
import { useMediaQuery } from '@material-ui/core';

export const IsPortraitContext = createContext<boolean>(false);
export const useIsPortrait = () => useContext(IsPortraitContext)!;

export const IsPortraitProvider: FC = ({ children }) => {
    const isPortrait = useMediaQuery('(orientation: portrait)');

    useEffect(() => {
        const metaViewport = document.querySelector("meta[name=viewport]")
        metaViewport?.setAttribute("content", `height=${window.innerHeight}, width=device-width, initial-scale=1.0`)
    }, [])

    return (
        <IsPortraitContext.Provider value={isPortrait}>
            {children}
        </IsPortraitContext.Provider>
    )
}
