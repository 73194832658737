import { createI18nText } from "../createTextFunctions";

export const PaymentTexts = createI18nText({
	he: {
		ohNo: 'אוי לא!',
		dem: 'שגיאה בהבאת המידע, נסו שוב',
		pem: 'שגיאה בתשלום, אנא נסו שוב',
		ta: 'נסה שוב',
		popup: {
			title: 'התשלום בוטל בהצלחה',
			text: 'ניתן לערוך את פרטי הרכישה בלחיצה על הכפתור',
			btn: 'חזרה לרכישה'
		},
		bp: {
			title: 'הזמנתך התקבלה בהצלחה!',
			titleStart: 'הזמנתך',
			titleEnd: 'התקבלה בהצלחה!',
			orderNumber: 'מספר הזמנה',
			sTitle: 'באזור האישי ניתן:',
			page: 'לראות את פרטי ההזמנה והקבלות',
			pen: 'לבצע שינויים',
			station: 'לראות את פרטי תחנת החלוקה',
			button: 'לאזור אישי',
			share: 'שתף ב-'
		},
		projectName: "שם המוצר",
		orgName: "שם העמותה",
		expireDate: "תאריך תפוגה",
		code: "קוד הרשיון",
		licenseNum: "מספר המשתמשים המותרים",
		username: "שם משתמש",
		name: "שם",
		organization_name: "שם עמותה",
		fullName: 'שם מלא',
		phone: 'מספר פלאפון',

		cost: "מחיר",
		number_of_licenses: "מספר משתמשים",
		years_to_expire: "לכמה שנים תקף הרשיון",
	},

	en: {
		ohNo: 'Oh no!',
		dem: 'Error receiving data, please try again',
		pem: 'Error paying, please try again',
		ta: 'Try again',
		popup: {
			title: 'Payment successfully canceled',
			text: 'You can edit the purchase details by clicking the button',
			btn: 'Back to purchase'
		},
		bp: {
			title: 'Your order has been successfully received!',
			titleStart: 'Your order',
			titleEnd: 'has been successfully received!',
			orderNumber: 'Order number',
			sTitle: 'In your profile you can:',
			page: "See your order's details and receipts",
			pen: 'Make changes',
			station: 'See the details of the distribution station',
			button: 'To your profile',
			share: 'Share on'
		},
		projectName: "Product name",
		orgName: "Organization's name",
		expireDate: "Expiration date",
		code: "LIcense's code",
		licenseNum: "NUmber of users allowed",
		username: "Username",
		name: "Name",
		organization_name: "Organization name",
		fullName: 'Full name',
		phone: 'Phone',

		cost: "Price",
		number_of_licenses: "Number of users",
		years_to_expire: "Years to license expiration",
	}
})