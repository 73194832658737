import { createI18nText } from "../createTextFunctions";


export const SupportText = createI18nText({
    he: {
        support: 'תמיכה',
        mail: 'מייל',
        subject: 'נושא',
        content: 'תוכן',
        contactUs: 'נתקלתם בבעיה? צרו איתנו קשר!',
        success: 'הבקשה נשלחה בהצלחה!'
    },
    en: {
        support: 'Support',
        mail: 'Mail',
        subject: 'Subject',
        content: 'Content',
        contactUs: 'Did you encounter a problem? Contact us!',
        success: 'The request was sent successfully!'
    }
})