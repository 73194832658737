import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDirection, useI18n, useTransformObject, Localize } from "../../translation/i18n";
import BlueProfile, { ReactComponent as Profile } from '../../icons/profile_blue.svg';
import { ReactComponent as BottomLeaf } from '../../icons/reviews/bottom-leaf.svg';
import { ReactComponent as LeafyPlant } from '../../icons/reviews/leafy-plant.svg';
import { ReactComponent as GirlWheel } from '../../icons/reviews/girl-on-wheel.svg';
import { ReactComponent as TallPlant } from '../../icons/reviews/plant.svg';
import { ReactComponent as TableLamp } from '../../icons/reviews/lamp-table.svg';
import { ReactComponent as TallLamp } from '../../icons/reviews/tall-lamp.svg';
import { ReactComponent as WebGirl } from '../../icons/reviews/web_girl.svg';
import { ReactComponent as TopLeaf } from '../../icons/reviews/top-leaf.svg';
import { ReactComponent as Plant } from '../../icons/plant.svg';
import { ReactComponent as Web } from '../../icons/reviews/Web.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAsyncEffect } from "@hilma/tools";
import { Recommender } from "../../common/types";
import { Navigation, Pagination } from 'swiper';
import { useAlert } from "@hilma/forms";
import axios from "axios";
// import '../../style/Generic.scss';
import '../../style/Reviews.scss';
import { Shapes } from "../Shapes";
import { ReactComponent as Left } from '../../icons/left.svg';
import { CircularProgress } from "@material-ui/core";

type Review = Recommender & { projectName: string };

export const Reviews: React.FC = ({ }) => {
    const [loading, setLoading] = useState(true);
    let [reviews, setReviews] = useState<Review[]>([]);

    const _reviews = useRef<Localize<Review>[]>([]);
    const transform = useTransformObject();
    const errors = useI18n(i => i.errors);
    const text = useI18n(i => i.homepage);
    const dir = useDirection();
    const alert = useAlert();

    const bgs = useMemo(() => [{
        id: 0, bg: <div className="bg-container">
            <GirlWheel className="girl-wheel" />
            <TallPlant className="tall-plant" />
            <Shapes className="rect lemon big"
                left={dir === 'rtl' ? "32vw" : "initial"}
                top="12%"
                right={dir === 'rtl' ? 'initial' : "32vw"}
                bottom='initial' />
            <Shapes className="rect blue-gray big"
                left={dir === 'rtl' ? "18vw" : "initial"}
                top="0%"
                right={dir === 'rtl' ? 'initial' : "18vw"}
                bottom='initial' />
            <Shapes className="rect blue-gray big"
                right={dir === 'rtl' ? "8vw" : 'iniial'}
                bottom="40%"
                top="initial"
                left={dir === 'rtl' ? "initial" : "8vw"} />
        </div>
    },
    {
        id: 1, bg: <div className="bg-container">
            <LeafyPlant className="leafy-plant" />
            <TableLamp className="table-lamp" />
            <Shapes className="rect blue-gray medium"
                top="28%"
                left={dir === 'rtl' ? "19vw" : 'initial'}
                right={dir === 'rtl' ? 'initial' : "19vw"}
                bottom='initial' />
            <Shapes className="rect blue-gray medium"
                right={dir === "rtl" ? "20vw" : "initial"}
                left={dir === "rtl" ? "initial" : "20vw"}
                bottom="initial"
                top="70%" />
            <Shapes className="rect lemon medium"
                right={dir === "rtl" ? "18vw" : "initial"}
                top="90%" />
            <Shapes className="circle small yellow opacity-h"
                top="10%"
                left={dir === 'rtl' ? "8vw" : 'initial'}
                right={dir === 'rtl' ? 'initial' : '8vw'} />
            <Shapes className="circle tiny blue"
                top="0%"
                left={dir === 'rtl' ? "20vw" : 'initial'}
                right={dir === 'rtl' ? 'initial' : '20vw'} />
        </div>
    },
    {
        id: 2, bg: <div className="bg-container">
            <Web className="web" />
            <WebGirl className="web-girl" />
            <TopLeaf className="top-leaf" />
            <BottomLeaf className="bottom-leaf" />
            <Shapes className="rect big lemon"
                top="15%"
                right={dir === 'rtl' ? "35%" : 'initial'}
                left={dir === 'rtl' ? 'initial' : "35%"} />
            <Shapes className="rect big blue-gray"
                bottom="30%"
                top='initial'
                right={dir === 'rtl' ? "8%" : 'initial'}
                left={dir === 'rtl' ? 'initial' : "8%"} />
        </div>
    },
    {
        id: 3, bg: <div className="bg-container">
            <TallLamp className="tall-lamp" />
            <Plant className="plant" />
            <Shapes className="rect big lemon"
                right={dir === 'rtl' ? "initial" : '30vw'}
                top="25%"
                left={dir === 'rtl' ? "30vw" : 'initial'}
                bottom='initial' />
            <Shapes className="rect big blue-gray"
                top="15%"
                left={dir === 'rtl' ? "18vw" : 'initial'}
                right={dir === 'rtl' ? 'initial' : '18vw'}
                bottom='initial' />
            <Shapes className="circle small yellow opacity-h "
                bottom="8%"
                top="initial"
                right={dir === 'rtl' ? "13vw" : 'initial'}
                left={dir === 'rtl' ? 'initial' : "13vw"} />
            <Shapes className="circle extra-tiny blue"
                bottom="25%"
                top="initial"
                right={dir === 'rtl' ? "7vw" : 'initial'}
                left={dir === 'rtl' ? 'initial' : '18vw'} />
        </div>
    }], [dir])

    useAsyncEffect(async () => {
        try {
            const { data: revs } = await axios.get<Localize<Review>[]>('/api/product/getReviews')
            _reviews.current = revs;
            setReviews(revs.map(rev => transform<Review>(rev)));
        } catch (err) {
            alert(errors.bringData);
        }
        setLoading(false);
    }, [])

    useEffect(() => {
        setReviews(_reviews.current.map(rev => transform<Review>(rev)));
    }, [_reviews, transform])

    return <div className={"reviews-container " + dir}>
        <div className="title">
            {text.reviews}
        </div>
        <div className="reviews">
            {loading ? <CircularProgress style={{ margin: "auto" }} /> :
                <Swiper
                    pagination={{ clickable: true }}
                    modules={[Pagination, Navigation]}
                    slidesPerView={1}
                    navigation={{
                        nextEl: '.swiper-button-unique.next',
                        prevEl: '.swiper-button-unique.prev'
                    }}
                >
                    {reviews.length ? reviews.map((review: any, i) => {
                        return <SwiperSlide key={i}>
                            <div className={"review-container "}>
                                {bgs.find(bg => { return bg.id === i % 4 })?.bg}
                                <div className={"profile " + Boolean(i % 2)}>
                                    {review.image ? <img src={review.image} onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = BlueProfile;
                                    }} /> : <Profile />}
                                </div>
                                <div className={"text " + Boolean(i % 2)}>
                                </div>
                                <div className="text-part">
                                    <div className="review">{review.text}</div>
                                    <div className="from">{review.title}</div>
                                    <div className="project">{review.projectName}</div>
                                </div>
                            </div>
                        </SwiperSlide>
                    }) : <div className='error'>{text.noReviews}</div>}
                </Swiper>}
            <div className="swiper-button-unique next"><Left /></div>
            <div className="swiper-button-unique prev"><Left /></div>
        </div>
    </div>
}