import { Accordion, AccordionDetails, AccordionSummary, Button, Chip } from "@material-ui/core"
import { downloadServerFile, useAlert } from '@hilma/forms';
import { useDirection, useI18n } from '../translation/i18n';
import React, { useState } from 'react'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { useAsyncEffect } from '@hilma/tools'
import ExpandMoreRounded from '@material-ui/icons/ExpandMoreRounded';
import { useIsPortrait } from '../common/context/IsPortraitContext';
import { isNotNullish } from '../common/functions/isNullish';
import { makeStyles } from '@material-ui/styles';
import { Skeleton } from '@material-ui/lab'
import { Shapes } from '../components/Shapes';
import axios from 'axios'
import '../style/receipts.scss';

interface Active extends Refund {
    status: 'success' | 'refund',
    license: any;
    expireDate: Date;
    licenseName: string;
    code: string;
    type: string;
    licensesNum: number;
}

interface Refund {
    id: number;
    approveDate: Date | null;
    suffix: string | null;
    asmachta: string | null;
    paid: number;
    projectName: string;
    refundId?: number
}

export const Receipts = () => {
    const [data, setData] = useState<Active[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const direction = useDirection();
    const text = useI18n(i => i.receipts);
    const errors = useI18n(i => i.errors);
    const alert = useAlert()
    const classes = useStyles();
    const isPortrait = useIsPortrait();

    useAsyncEffect(async () => {
        setLoading(true);
        try {
            const { data } = await axios.get<Active[]>('/api/receipt/receipts-list');
            setData(data);
        } catch (err) {
            alert(errors.generalError);
        }
        setLoading(false);
    }, []);

    const downloadReceipts = async (purchaseId: number, refundId?: number) => {
        try {
            await downloadServerFile(
                `/api/receipt/download-receipt`,
                `hilma101-receipt.pdf`,
                { method: 'POST', data: { purchaseId, refundId } },
                true
            )
        } catch (err) {
            alert(errors.downloadError);
        }
    }

    return (
        <div className='receipts-container' style={{ direction }}>
            <div className="title">{text.receipts}</div>
            <div className="receipts-list">

                {loading ? [...Array(5)].map(() => <Skeleton height='10vh' />) :
                    !data.length ? <p className="info-p">{text.noData}</p>
                    : data.map((tr: Active, index: number) => (
                        <Accordion classes={{ root: classes.root, expanded: classes.expanded }}>

                            <AccordionSummary
                                expandIcon={<ExpandMoreRounded />}
                                aria-controls="panel1bh-content"
                                classes={{ root: classes.summary }}
                            >
                                <div className="name-and-status">
                                    <div className={`product-date ${direction}`}>
                                        <div className='product-name'>{tr.projectName ?? '--'}</div>
                                        <div className="date">{tr.approveDate ?? '--'}</div>
                                    </div>
                                    {tr.status === 'refund' ? <Chip className='refund' label={text[tr.status] ?? '--'} /> : null}
                                </div>
                            </AccordionSummary>
                            <AccordionDetails classes={{ root: classes.details }}>
                                <div className="info">

                                    {['expireDate', 'code', 'paid', 'suffix', 'licensesNum'].map((att) => {
                                        return (isNotNullish(tr?.[att as keyof Active]) ? <div className='field' key={att + " " + index}>
                                            <div className='label'>{text[att as keyof typeof text]}</div>
                                            <div className='value'>{`${tr?.[att as keyof Active]} ${att === 'paid' ? '₪' : ''}`}</div>
                                        </div> : null)
                                    })}
                                    <Button onClick={() => downloadReceipts(tr.id, tr?.refundId)} variant='outlined' classes={{ root: classes.downloadButton }}>
                                        <GetAppOutlinedIcon />
                                        <div className='value'>{text.download}</div>
                                    </Button>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
            </div>
            {!isPortrait ?
                <>
                    <Shapes className="rect lemon big" left={direction === 'rtl' ? "9vw" : "initial"} top="30px" right={direction === 'rtl' ? 'initial' : "9vw"} bottom='initial' />
                    <Shapes className="rect blue-gray big" left={direction === 'rtl' ? "3vw" : "initial"} top="100px" right={direction === 'rtl' ? 'initial' : "3vw"} bottom='initial' />
                </> : null}
        </div>
    )
}

const useStyles = makeStyles({
    details: {
        padding: 0,
    },
    root: {
        boxShadow: 'unset',
        '&:before': {
            backgroundColor: 'unset'
        },
        borderBottom: '1px solid #e6e6e6'
    },
    expanded: {
        margin: '0 !important'
    },
    summary: {
        padding: '0 !important',
    },
    downloadButton: {
        marginTop: '2vh',
        borderColor: '#1a3650',
        color: '#1a3650'
    }

})
