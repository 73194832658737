import { createI18nText } from "../createTextFunctions";

export const OrgPruchaseText = createI18nText({
    he: {
        purchaseOptions: 'אפשרויות רכישה',
        chooseOptionsMessage: 'בחרו את התכנית המתאימה עבורכם',
        private: "הורדה חינם",
        institutional: "לרכישה",
        customized: "דברו איתנו",
        dataError: 'ארעה שגיאה בהבאת הנתונים',
        popupTitle: " פרטי יצירת קשר",
        popupBodyTop: "על מנת לקנות מוצר זה אנא צרו קשר:",
        popupPhone: "מספר טלפון: ",
        popupEmail: "כתובת מיל: "
    },
    en: {
        purchaseOptions: "Purchase Options",
        chooseOptionsMessage: "Choose the program that suits you",
        private: "Download",
        institutional: "Purchase",
        customized: "Contact Us",
        dataError: 'An error accured while trying to get the information',
        popupTitle: "Contact Details",
        popupBodyTop: "In order to purchase the item, contact us:",
        popupPhone: "Phone: ",
        popupEmail: "Email: "
    }
})