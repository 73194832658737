import { createRules, FormInput, FormProvider, useAlert, useForm, useUpdateOnSubmit } from '@hilma/forms';
import { useDirection, useI18n, useLanguage, useTranslate } from "../translation/i18n";
import { MouseEventHandler, useMemo, useState } from 'react';
import { HEBREW_REGEX, NAME_REGEX, PASSWORD_WITH_STARS } from '../common/consts/regex.consts';
import { UserTypes, USER_TYPE_OPTIONS } from '../common/consts/orgTypeOptions';
import { provide, useAsyncEffect } from '@hilma/tools'
import { Button, ButtonBase } from '@material-ui/core';
import { User, useUser101 } from '../common/context/User101Context';
import RadioGroupInput from '../components/RadioGroupInput';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import '../style/ProfileNSupportForms.scss';

export const profileRules = createRules({
    username: { required: true, name: 'אימייל', regex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ },
    name: { required: true, name: 'שם', regex: NAME_REGEX },
    phone: { required: true, name: 'מספר טלפון', regex: /^[0-9]{9,16}$/ },
    orgName: { required: true, regex: HEBREW_REGEX },
    password: { required: true, regex: PASSWORD_WITH_STARS },
    userType: { required: true, options: UserTypes }
})

export const initialValues = {
    name: '',
    username: '',
    password: '',
    phone: '',
    orgName: '',
    isMissing: false,
    userType: '',
    isGoogleUser: true
}

export const EMPTY_PASSWORD = '*********'

const Profile = () => {
    const [disabledPassword, setDisabledPassword] = useState(true);
    const [loading, setLoading] = useState(true);
    const [initialForm, setInitialForm] = useState<typeof initialValues>(JSON.parse(JSON.stringify(initialValues)));
    const form = useForm();
    const dir = useDirection();
    const text = useI18n(i => i.profile)
    const errors = useI18n(i => i.error)
    const login = useI18n(i => i.login)
    const { user, setUser } = useUser101();
    const alert = useAlert();
    const classes = useStyles();

    const userTypes = useMemo(() => USER_TYPE_OPTIONS.map(op => ({ ...op, value: login[op.id] })), [login]);
    const noChanges = useMemo((): boolean => {
        let changed: boolean = false
        Object.keys(initialForm).forEach((key) => {
            if (initialForm[key as keyof typeof initialForm] !== form.values[key]) changed = true;
        });
        return !changed;
    }, [form.values]);

    useAsyncEffect(async () => {
        try {
            const { data } = await axios.get<User>("/api/101/user/get-profile");
            data.password = EMPTY_PASSWORD;
            Object.keys(data).forEach(key => { !data[key as keyof User] && (delete data[key as keyof User]) });
            form.setValues({ ...initialValues, ...data, isGoogleUser: Boolean(data.isGoogleUser) });
            setInitialForm({ ...initialValues, ...data, phone: data.phone?.substring(1) || "", isGoogleUser: Boolean(data.isGoogleUser) });
            form.setFieldValue('phone', data.phone?.substring(1) || "");
            setLoading(false);
        } catch (err) {
            alert(errors.errorProfileData);
        }
    }, [])

    useUpdateOnSubmit(() => {
        if (noChanges) {
            alert("לא התבצעו שום שינויים", "info");
            return;
        }
        setLoading(true);
        (async () => {
            try {
                await axios.post('/api/101/user/profile-update', {
                    ...form.values,
                    password: !disabledPassword ? form.values.password : null,
                    phone: `+972${form.values.phone}`,
                    username: form.values.username
                })
                setUser(prev => {
                    if (!prev) return prev;
                    prev && (prev.isMissing = false);
                    return { ...prev }
                })
                alert(errors.saveProfile, 'success');
            } catch (err) {
                console.log(err);
                alert(errors.saveProfileFailed);
            }
        })()
        setLoading(false);
    })

    const editPassword = () => {
        setDisabledPassword(prev => {
            form.setFieldValue('password', !prev ? EMPTY_PASSWORD : '');
            return !prev;
        });
    }

    return (
        <div className={"profile-container " + dir} style={{ direction: dir }}>
            <div className="title">{text.profile}</div>
            {user?.isMissing ? <div className="is-missing">{text.isMissing}</div> : null}
            <FormInput loading={loading} id='name' description={text.name} classes={{ label: "label-x", errorContainer: "error-msg", container: "input-container" }} />
            <FormInput loading={loading} disabled={form.values.isGoogleUser} id='username' description={text.username} classes={{ label: "label-x", errorContainer: "error-msg", container: "input-container ltr" }} />
            {!form.values.isGoogleUser ?
                <FormInput loading={loading} disabled={disabledPassword} id='password' description={text.password} classes={{ label: "label-x", errorContainer: "error-msg", container: "input-container password-container" }}
                    endAdornment={
                        <ButtonBase onClick={editPassword}>{text.replacePassword}</ButtonBase>
                    }
                /> : null}
            <FormInput style={{ textAlign: 'left' }} loading={loading} id='phone' description={text.phone} classes={{ label: "label-x", errorContainer: "error-msg", container: "input-container ltr phone" }}
                endAdornment={
                    <div>{`972+`}</div>
                }
            />
            <RadioGroupInput disabled={loading} id="userType" description={text.userType} options={userTypes} />
            <FormInput loading={loading} id='orgName' description={text.orgName} classes={{ label: "label-x", errorContainer: "error-msg", container: "input-container" }} />
            <Button className="save-button" classes={{ root: classes.sendButton, label: classes.buttonText }} disabled={loading || !!noChanges} children={text.save} onClick={form.handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>} />
        </div>
    )
}

export default provide([
    FormProvider, {
        initialValues,
        rules: profileRules,
        customI18n: {
            usei18n: useI18n, useTranslate, useDirection, useLanguage: useLanguage as () => string
        }
    }
])(Profile);


export const useStyles = makeStyles({
    sendButton: {
        alignSelf: 'flex-end',
        backgroundColor: '#1a3650',
        marginTop: '1vh',
        width: '8vw',
        '&:hover': {
            backgroundColor: '#1a3650',
        }
    },
    buttonText: {
        color: 'white',
        fontWeight: 700,
        fontSize: '1.1rem',
    }
})