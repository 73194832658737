import { Localize, useDirection, useI18n } from "../../translation/i18n";
import { ProductThumbnail } from "./ProductThumbnail";
import React, { useState } from "react";
import { useAsyncEffect, } from "@hilma/tools";
import { ProductInfo } from "../../common/types/ProductInfo.type";
import axios from "axios";
import '../../style/Generic.scss';
import '../../style/OurSolutions.scss';
import { useAlert } from "@hilma/forms";
import { CircularProgress } from "@material-ui/core";

export const OurSolutions: React.FC = ({ }) => {
    const [loading, setLoading] = useState(true);
    const [solutions, setSolutions] = useState<Localize<ProductInfo>[]>([])//Its not translated, so if language - no need to re-fetch.

    const text = useI18n(i => i.homepage)
    const errors = useI18n(i => i.errors)
    const dir = useDirection();
    const alert = useAlert();

    useAsyncEffect(async () => {
        try {
            const { data } = await axios.get<Localize<ProductInfo>[]>('/api/product')
            setSolutions(data)
        } catch (err) {
            alert(errors.bringData);
        }
        setLoading(false)
    }, [])

    return <div className={"ourSolutions-container " + dir}>
        <div className="title">
            {text.ourSolutions}
        </div>
        <div className="products">
            {loading ? <CircularProgress style={{ margin: "auto" }} /> : solutions.map(item => <ProductThumbnail info={item} key={item._id} />)}
        </div>
    </div>
}