import { ReactComponent as PurchaseDefaultImg } from '../../icons/purchase.svg';
import { useI18n, useLanguage } from "../../translation/i18n";
import { Link, useParams } from "react-router-dom";
import React, { useMemo } from "react";
import { InfoStrip } from "../../common/types";
import '../../style/Product.scss'

interface OrganizationPrucahseButtonProps {
    info: InfoStrip
}

export const OrganizationPrucahseButton: React.FC<OrganizationPrucahseButtonProps> = ({ info }) => {
    const btnText = useI18n(i => i.product.org_purchase);
    const { name } = useParams<"name">()
    const lang = useLanguage();

    const text = useMemo(() => {
        return info.text.replaceAll(/(<h\d)/g, '$1 class="slogen"').replaceAll('<p>', '<p class="editor-paragraph">')
    }, [info.text])

    return <div className="org-purchase-container">
        <div className="text-container">
            <div className="texts">
                <div className="text">
                    <p className="editor-title">{info?.title}</p>
                </div>
                <div className="text" dangerouslySetInnerHTML={{ __html: text }}></div>
            </div>

            <Link className='link-to-purchase' to={`/${lang}/organization-purchase/${name}`}>
                <button className="purchase-button">{btnText}</button>
            </Link>
        </div>
        <div className={`img-container ${!(info?.image) && "empty"}`}>
            {info?.image ? <img src={info.image} alt="" /> : <PurchaseDefaultImg />}
        </div>
    </div>
}