import { FC } from 'react';
import { Link } from 'react-router-dom'
import { ReactComponent as Globe } from '../icons/globe.svg'
import { usePathLanguage, useSwitchedPath } from '../translation/i18n';

const LangsNames = {
    he: 'עב',
    en: 'ENG'
};

interface LanguageButtonProps {
    className?: string
}

export const LanguageButton: FC<LanguageButtonProps> = ({ className }) => {
    const newLanguagePath = useSwitchedPath();
    const path = usePathLanguage();

    return (
        <div className="lang">
            <Link to={newLanguagePath}>
                <div className="language-button">{LangsNames[path === 'he' ? 'en' : 'he']}</div>

                {/* <Globe className={className} />
                <div className='language'>{LangsNames[path === 'he' ? 'en' : 'he']}</div> */}
            </Link>
        </div>
    )
}
