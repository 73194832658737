import { ReactComponent as Download } from '../../icons/download.svg'
import { useI18n, usePathLanguage } from "../../translation/i18n"
import { useNavigate, useParams } from "react-router-dom"
import { ExitToApp } from "@material-ui/icons"
import { useMemo } from "react"
import { Product } from "../../common/types"

interface DownloadButtonProps {
    product?: Product
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({ product }) => {
    const text = useI18n(i => i.product)
    const path = usePathLanguage();
    const navigate = useNavigate();
    const params = useParams<"name">();

    const buttonType = useMemo(() => {
        return product?.download?.leaveDetailsIframe ? 'contact'
            : (product?.download && ('android' in product.download || 'ios' in product.download)) ? 'app' :
                product?.download?.web ? 'web' : 'none';
    }, [product])

    const goToDownloadPage = () => {
        if (!product?.download) return;
        switch (buttonType) {
            case 'app':
                navigate(`/${path}/download/${params.name}`);
                return;

            case 'contact':
                navigate(`leaveDetails/`, { state: { url: product?.download.leaveDetailsIframe } });
                return;

            case 'web':
                product?.download.web && window.open(product?.download.web, '_blank');
        }
    }

    const buttonText = useMemo(() => {
        switch (buttonType) {
            case 'app':
                return text.download;
            case 'contact':
                return text.leaveDetails;
            case 'web':
                return text.goToWebsite;
            default: return "";
        }
        // eslint-disable-next-line
    }, [buttonType])


    if (buttonType === "none")
        return <></>;
    return <div className="download-btn-container">
        <button disabled={!(product?.download)} className="download-btn" onClick={goToDownloadPage}>
            {buttonType === 'app' ? <Download className="download-icon" /> : <ExitToApp className="download-icon" style={{ height: "auto" }} />}
            <div className="text">
                {buttonText}
            </div>
        </button>
    </div>
}