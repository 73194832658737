import { ReactComponent as People } from '../../icons/home_people.svg';
import { useDirection, useI18n } from "../../translation/i18n";
import React, { useState } from "react";
import coffeeImg from '../../icons/coffee.png';
import smediaGif from '../../icons/smedia1.gif';
import smediaImg from '../../icons/smedia.png';
import coffeeGif from '../../icons/coffee1.gif';
import ideaImg from '../../icons/idea.png';
import ideaGif from '../../icons/idea1.gif';
import '../../style/SolutionsForYou.scss';

export const SolutionsForYou: React.FC = ({ }) => {
    const [ideaAnim, animateIdea] = useState(false)
    const [smediaAnim, animateSMedia] = useState(false)
    const [coffeeAnim, animateCoffee] = useState(false)

    const text = useI18n(i => i.homepage)
    const dir = useDirection();
    
    return <div className={"solutionsForYou-container " + dir}>
        <div className="heading">
            <div className="heading-text">
                <div className="sub1">{text.solutions4u}</div>
                <div className="sub2">{text.productCats}</div>
            </div>
            <div className="people-img-container">
                <People className="people" />
            </div>
        </div>
        <div className="bullet-points-container">
            <div className="bullet-point" onMouseOut={() => animateIdea(false)} onMouseOver={() => animateIdea(true)}>
                <div className="icon-container"  >
                    {!ideaAnim ? <img src={ideaImg} alt="" /> :
                        <img src={ideaGif} alt="idea" />}
                </div>
                <div className="sub">{text.storePoint}</div>
            </div>
            <div className="bullet-point" onMouseOut={() => animateCoffee(false)} onMouseOver={() => animateCoffee(true)}>
                <div className="icon-container"  >
                    {!coffeeAnim ? <img src={coffeeImg} alt="" /> :
                        <img src={coffeeGif} alt="coffee" />}
                </div>
                <div className="sub">{text.communicationPoint}</div>
            </div>
            <div className="bullet-point" onMouseOut={() => animateSMedia(false)} onMouseOver={() => animateSMedia(true)}>
                <div className="icon-container"  >
                    {!smediaAnim ? <img src={smediaImg} alt="" /> :
                        <img src={smediaGif} alt="social media" />}
                </div>
                <div className="sub">{text.agesPoint}</div>
            </div>
        </div>
    </div >

}