import { NoTokensStorage, useHasTwoFactorToken, useIsAuthenticated, useLoginWithCode, useRemoveAuthItem, useSendCode } from "@hilma/auth";
import { useNavigate, Navigate, useSearchParams, Link, useLocation, Location } from 'react-router-dom'
import { useDirection, useI18n, usePathLanguage, useTranslate, useLanguage } from "../translation/i18n";
import { FormInput, FormProvider, useAlert, useForm, useUpdateOnSubmit } from "@hilma/forms";
import React, { MouseEventHandler, useEffect, useMemo, useState } from "react";
import { ReactComponent as TallPlant } from '../icons/reviews/plant.svg';
import { ReactComponent as People } from '../icons/home_people.svg';
import { FormPasswordInput } from "../components/FormPasswordInput";
import { TWO_FACTOR_COOKIE } from "../common/consts/two-factor.const";
import { useIsPortrait } from "../common/context/IsPortraitContext";
import { ArrowForward } from "@material-ui/icons";
import { useUser101 } from "../common/context/User101Context";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { provide } from "@hilma/tools";
import { Shapes } from "../components/Shapes";
import * as Yup from 'yup';
import axios from "axios";
import '../style/Login.scss'

interface Storage extends NoTokensStorage {
    [TWO_FACTOR_COOKIE]: string
}

interface StateInterface {
    productName?: string
}

const Login: React.FC<{}> = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [isRestore, setIsRestore] = useState<boolean>(false);

    const hasTwoFactorToken = useHasTwoFactorToken();
    const isAuthenticated = useIsAuthenticated();
    const loginWithCode = useLoginWithCode();
    const removeAuthItem = useRemoveAuthItem();
    const sendCode = useSendCode();
    const navigate = useNavigate();
    const { state }: Location = useLocation();
    const path = usePathLanguage();
    const lang = useLanguage();
    const dir = useDirection();
    const alert = useAlert()
    const [search] = useSearchParams();
    const isPortrait = useIsPortrait();
    const { user } = useUser101();
    const form = useForm()
    const classes = useStyles()
    const text = useI18n(i => i.login);
    const errorTexts: any = useI18n(i => i.errors);

    const isValid = useMemo(() => {
        return form.isValid && Boolean(hasTwoFactorToken ? form.values.code : (form.values.username && form.values.password))
    }, [form, hasTwoFactorToken]);

    const productName = useMemo(() => {
        const name = search.get('productName');
        return (state as StateInterface)?.productName || name;
    }, [state, search]);

    useEffect(() => {
        if (search.get("message") === "DELETED") {
            alert("משתמש נמחק בהצלחה", 'success');
        }
        // eslint-disable-next-line
    }, [search])

    useUpdateOnSubmit(() => {
        (async () => {
            await login();
        })()
    })

    useEffect(() => {
        const error = search.get('error');
        if (error) alert(errorTexts[error] || errorTexts['generalError']);
        setLoading(false);
        // eslint-disable-next-line
    }, [])

    const login = async () => {
        setLoading(true);
        const { success, msg } = await (hasTwoFactorToken ?
            loginWithCode('/api/two-factor/login-with-code-101', form.values.code) :
            sendCode('/api/two-factor/send-code-101', { username: form.values.username, password: form.values.password })
        );
        setLoading(false);
        console.log(productName);

        if (success) return;
        console.log(msg);
        alert(errorTexts[msg?.data?.key || msg?.data?.error] || errorTexts.generalError);
    }

    const connectGoogle = () => {
        window.location.href = `${window.location.origin.replace(":3000", ":8080")}/google/login?lang=${path}&productName=${productName || ""}`
    }

    const moveToRestorePassword = () => {
        setIsRestore(true);
    }

    const backToLogin = () => {
        setIsRestore(false);
    }

    const restorePassword = async () => {
        try {
            await axios.post('/api/mails/send-reset-mail-user101', { username: form.values.username, lang });
            alert(text.sendResetPasswordSuccess, 'success');
            backToLogin()
        } catch (err: any) {
            console.log('err: ', err);
            alert(errorTexts[err.data.error === 'EMAIL_NOT_FOUND' ? 'NoUsername' : err.data.error] || errorTexts['generalError'])
        }
    }

    const goBack = () => {
        if (!isRestore) removeAuthItem<Storage>(TWO_FACTOR_COOKIE);
        else backToLogin();
    }

    return isAuthenticated && user ? productName ? <Navigate to={`/${lang}/organization-purchase/${productName || ""}`} /> :
        <Navigate to={`/${lang}/${user?.isMissing ? 'profile' : 'private-area'}`} state={state || {}} /> :
        <div className={"login-container " + dir}>
            <div className="bottom-blue"></div>
            {!isPortrait ?
                <><div className="right-design">
                    <TallPlant className="tall-plant" />
                    <Shapes className='rect medium opacity-s purple' right="2vw" bottom="45%" top="initial" />
                    <Shapes className='rect medium opacity-s neon-carrot' right="4vw" bottom="61%" top="initial" />
                </div>
                    <div className="side-image">
                        <People className="people" />
                        <Shapes className='rect medium opacity-s purple' right="57vw" bottom="7%" top="initial" />
                        <Shapes className='rect medium opacity-s neon-carrot' right="50vw" bottom="2%" top="initial" />

                    </div></> : null}
            <div className="side-text center">
                <h2 className="title">
                    {hasTwoFactorToken || isRestore ? <div className="code-with-back">
                        <IconButton style={{ padding: 0, color: '#1a3650' }} onClick={goBack}>
                            <ArrowForward />
                        </IconButton>
                    </div> : null}
                    {productName ? <h5>{text.redirectedTitle1}</h5> : text.signInTitle}
                    {productName ? <h6>{text.redirectedTitle2}</h6> : ""}
                </h2>
                <div className="inputs">

                    {isRestore || (!hasTwoFactorToken && !isRestore) ?
                        <FormInput classes={{ label: classes.label }} id="username" description={text.username || ''} /> : null}
                    {(!hasTwoFactorToken && !isRestore) ?
                        <FormPasswordInput classes={{ label: classes.label }} id="password" description={text.password || ''} /> : null}
                    {hasTwoFactorToken && !isRestore ?
                        <FormInput classes={{ label: classes.label }} id="code" description={text.code} />
                        : null}

                </div>
                <button aria-disabled={loading} className={"login-btn connect " + (isValid)} disabled={!(isValid /* && form.dirty */)} onClick={isRestore ? restorePassword : form.handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}>{text[isRestore ? "resetPassword" : (hasTwoFactorToken ? "signInBtn" : "sendCodeBtn")]}</button>
                {/* <button aria-disabled={loading} className={`restore-password ${!isRestore}`} onClick={moveToRestorePassword}>{text.forgotPassword}</button> */}
                <div className="alt-container">
                    <hr />
                    <div className="or-google-label">{text.orSignInWith}</div>
                </div>
                <button aria-disabled={loading} className={"connect google"} onClick={connectGoogle}>
                    Google
                    <img src="/icons/google.svg" alt="google-icon" className="google-g-logo" />
                </button>

                <div className='bottom-buttons'>
                    <button aria-disabled={loading} className="no-account-btn" onClick={() => navigate(`/${lang}/register`, { state: state || {} })}>{text.noAccount}</button>
                    <button aria-disabled={loading} className={`restore-password ${!isRestore}`} onClick={moveToRestorePassword}>{text.forgotPassword}</button>
                </div>

            </div>

            <Link to="/">
                <img className="hilma-logo" alt="logo" src="/icons/Hilma.svg" />
            </Link>
        </div>
}

const LoginSchema = Yup.object().shape({
    username: Yup.string()
        .email({ path: 'errors.uNameRegex' }),

    password: Yup.string(),
    // .matches(PASSWORD_REGEX, { message: { path: 'errors.pWordRegex' } }),

    code: Yup.string()
})

export default provide([
    FormProvider, {
        initialValues: { username: '', password: '', code: '' },
        validationSchema: LoginSchema,
        customI18n: {
            usei18n: useI18n, useTranslate, useDirection, useLanguage: useLanguage as () => string
        },
        rules: {}
    }
])(Login)


const useStyles = makeStyles({
    label: {
        color: '#1a3650'
    }
})
