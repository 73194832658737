import { useDirection, useI18n, useLanguage, useTransformObject } from "../translation/i18n";
import { OrganizationPurchaseOptions, PruchaseButtonType } from "../common/types";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { ReactComponent as WebNImg } from '../icons/web_and_img.svg';
import { ReactComponent as Plant } from '../icons/reviews/leafy-plant.svg';
import { useIsAuthenticated } from "@hilma/auth";
import { CircularProgress } from "@material-ui/core";
import { useAsyncEffect } from "@hilma/tools";
import { Localize } from "../translation/i18n";
import { useAlert } from "@hilma/forms";
import { useRef } from "react";
import { useIsPortrait } from "../common/context/IsPortraitContext";
import axios from "axios";
import '../style/OrganizationPurchase.scss'
import { Shapes } from "../components/Shapes";
import { ArrowForward } from "@material-ui/icons";
import GenericPopup from "../components/GenericPopup";

type GetPurchaseOptions = {
    name: string;
    projectId: number,
    organizationPurchaseOptions: OrganizationPurchaseOptions,
    purchasesTypesIds?: { private: number, institutional: number, customized: number }
}

type openType = 'contact' | 'login';

export const OrganizationPurchase: React.FC<{}> = ({ }) => {
    const [purchaseOptions, setPurchaseOptions] = useState<GetPurchaseOptions>();
    const [isOpen, setIsOpen] = useState<openType | null>(null)
    const [loading, setLoading] = useState(true);

    const _prucahse_not_translated = useRef<GetPurchaseOptions>()
    const texts = useI18n(i => i.organizationPurchase);
    const errors = useI18n(i => i.errors);
    const isAuthenticated = useIsAuthenticated();
    const transform = useTransformObject();
    const { name } = useParams<"name">();
    const navigate = useNavigate();
    const dir = useDirection();
    const lang = useLanguage();
    const alert = useAlert();
    const isPortrait = useIsPortrait();

    useAsyncEffect(async () => {
        setLoading(true);
        try {
            const { data } = await axios.get<Localize<GetPurchaseOptions>>('/api/product/get-proudct-extra', { params: { name, type: "purchase-options" } });
            _prucahse_not_translated.current = data;
            setPurchaseOptions(transform(data));
        } catch (err) {
            alert(errors.bringData)
        }
        setLoading(false);
    }, [])

    useEffect(() => {
        _prucahse_not_translated.current && setPurchaseOptions(transform(_prucahse_not_translated.current))
    }, [lang]);

    const onPurchaseOptionClick = (type: PruchaseButtonType) => {
        if (type === 'private') {
            navigate(`/${lang}/download/${name}`);
            return;
        } else if (type === 'customized') {
            setIsOpen('contact');
            return;
        }
        if (type === 'institutional') {
            // const productName = name;
            if (!isAuthenticated) {
                setIsOpen('login');
                return;
            }
            navigate(`/${lang}/payment/${purchaseOptions?.purchasesTypesIds?.institutional || ""}`)
            return;
        }
    }

    const goToLogin = () => {
        navigate(`/${lang}/login`, { state: { productName: name } })
    }

    return <div className={"organization-purchase-container " + dir}>
        <div className="x">
            <Link to={`/${lang}/product/${name}`}>
                <ArrowForward className='arrow' />
            </Link>
        </div>
        <div className="header">
            <div className='background'></div>
            <div className="text">
                <div className="product-name">
                    {purchaseOptions?.name || name}
                </div>
                <div className="purchase-options">{texts.purchaseOptions}</div>
            </div>
            <Plant className="plant" />
            <WebNImg className="web" />
            <Shapes className='rect medium blue-gray' bottom="10%" left="5vw" right="initial" top="initial" />
            <Shapes className='rect medium blue-gray' top="50%" right="50vw" />
            <Shapes className='rect medium yellow index-h lemon' right="38vw" left="initial" bottom={isPortrait ? "55%" : "70%"} top='initial' />
            <Shapes className='rect medium yellow index-h lemon' top="80%" right="48vw" />
            <Shapes className='circle transform tiny blue' top='28%' left="10.5vw" />
            <Shapes className='circle transform large yellow opacity-m' top={isPortrait ? "25%" : "50%"} left="0" />
        </div>
        <div className="subtitle">{texts.chooseOptionsMessage}</div>
        <div className="options">
            {loading ? <CircularProgress /> : purchaseOptions ? (['private', 'institutional', 'customized'] as PruchaseButtonType[]).map((type: PruchaseButtonType) => purchaseOptions?.organizationPurchaseOptions?.[type] &&
                <div className="option" key={type}>
                    <div className="option-title">{purchaseOptions?.organizationPurchaseOptions?.[type]?.title}</div>
                    <div className="details">
                        <div className="price">{purchaseOptions?.organizationPurchaseOptions?.[type]?.price}</div>
                        <div className="includes">
                            {purchaseOptions?.organizationPurchaseOptions?.[type]?.text}
                        </div>
                        <button className="action-btn" onClick={() => onPurchaseOptionClick(type)} >{texts[type]}</button>
                    </div>

                </div>) : <div className='error'>{texts.dataError}</div>}
        </div>

        <GenericPopup
            isOpen={!!isOpen}
            onClose={() => { setIsOpen(null) }}
            button={isOpen === "login" ? { text: "אישור", onClick: goToLogin } : undefined}
            title={texts.popupTitle} >
            {
                isOpen === "login" ? <div className="go-to-login">
                    על מנת לרכוש מוצר יש צורך בהתחברות.
                    <br />
                    בלחיצה על אישור נעביר אותך לעמוד הרשמה והתחברות
                </div> : <div className="contact-us-popup" >
                        {texts.popupBodyTop}
                        <br />
                        {texts.popupPhone} 02-0000000
                <br />
                        {texts.popupEmail} merav@hilma.tech
            </div>}
        </GenericPopup>

    </div>
}