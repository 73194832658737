import { createI18nText } from "../createTextFunctions";

export const NotFoundTexts = createI18nText({
    he: {
        pageDoesNotExist: "שגיאה! העמוד אותו ביקשת לא קיים",
        back: "לחזרה לעמוד הראשי לחץ ",
        here: "כאן"
    },
    en: {
        pageDoesNotExist: "Error! The page you were looking for does not exist",
        back: "In order to return to home page click ",
        here: "here"
    }
})