import { createI18nText } from "../createTextFunctions";

export const TableTexts = createI18nText({
    he: {
      product: 'מוצר',
      numberOfLicenses: 'מספר רישיונות',
      asmachta: 'אסמכתה',
      paid: 'מחיר',
      suffix: 'כרטיס',
      date: 'תאריך',
      status: 'סטטוס',
      more: "מידע נוסף",
      download: "הורדת קבלה",

      page: "עמוד",
      of: "מתוך"
    },
    en: {
      product: 'product',
      numberOfLicenses: 'number of licenses',
      asmachta: 'Asmachta',
      paid: 'Paid',
      suffix: 'Suffix',
      date: 'Date',
      status: 'Status',
      more: "More Info",
      download: "Download",

      page: "Page",
      of: "of"
    }
})