import { createTheme } from "@material-ui/core";


export const muiTheme = createTheme({
    palette: {

        primary: {
            main: '#1A3650'
        },

        error: {
            main: '#FF2A2A'
        }
    }
})