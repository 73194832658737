import { FC } from 'react'
import { ButtonBase } from '@material-ui/core'
import Google from '../../icons/google.png';
import Apple from '../../icons/apple.png';
import { useI18n } from '../../translation/i18n';
import { useIsPortrait } from '../../common/context/IsPortraitContext';

type Apps = 'android' | 'ios';

interface DownloadAppButtonProps {
    type: Apps,
    link: string,
    openApp: (type: Apps) => void,
    openQRPopup: (type: Apps) => void
}

const APP_IMAGES = {
    android: Google,
    ios: Apple
}

export const DownloadAppButton: FC<DownloadAppButtonProps> = ({ type, link, openApp, openQRPopup }) => {
    const text = useI18n(i => i.download);
    const isPortrait = useIsPortrait();
    
    const open = () => openApp(type);
    const openQr = () => openQRPopup(type);

    return (
        <div className="button-app">
            <ButtonBase disabled={!link} onClick={open}><img className="app" src={APP_IMAGES[type]} /></ButtonBase>
            {!isPortrait && link ? <div className="create-qr-code" onClick={openQr}>{text.createQrCode}</div> : null}
        </div>
    )
}
