import React, { useMemo } from 'react';
import '../style/shapes.scss';

export interface RectProps {
    className?: string;
    right?: string;
    left?: string;
    bottom?: string;
    top?: string;
}

export const Shapes: React.FC<RectProps> = ({ className, right, left, top, bottom }) => {

    const style = useMemo(() => {
        const body = { left, right, top, bottom }
        if (!left) delete body.left;
        if (!right) delete body.right;;
        if (!top) delete body.top;
        if (!bottom) delete body.bottom;
        return body;
    }, [right, left, top, bottom])

    return (
        <div className={`${className || "rect yellow"}`} style={style} ></div>
    )
}
