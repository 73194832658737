export const fillInTemplate = (string: string, info: Record<string, any>) => {
    let matches = string.match(/\{.+?\}/g);
    if (matches) {
        if (matches?.length > Object.values(info).length) {
            console.error("Template text-string has error: missing field");
        }
        let stringWithoutBracket = string.split(/\{.+?\}/g);
        return stringWithoutBracket.reduce((prevValue: string, str: string, index: number) => {
            if (matches![index]) {
                let field = matches![index].slice(1, -1);
                !field && console.error("Template text-string has error: missing field");
                return prevValue + (info[field] ? (str + `${info[field]}`) : '');
            }
            return prevValue + str;
        }, '');
    };
    return string;
};