export const ORG_TYPE_OPTIONS = {
    private: 1,
    association: 2
}

export enum UserTypes {
    PRIVATE = 'private',
    ASSOCIATION = 'association'
}

export const USER_TYPE_OPTIONS = [
    {
        id: UserTypes.PRIVATE,
        value: 'פרטי'
    },
    {
        id: UserTypes.ASSOCIATION,
        value: 'ארגון'
    }
]