import { ReactComponent as Plant } from '../../icons/plant.svg';
import { useI18n } from "../../translation/i18n";
import kranot from '../../icons/kranot_logo.png';
import React from "react";
import pais from '../../icons/pais_logo.png';
import '../../style/Partners.scss'

export const Partners: React.FC = () => {

    const text = useI18n(i => i.homepage)

    return <div className="partners-container">
        <div className="title">{text.partners}</div>
        <Plant className="plant" />
        <div className="logos">
            <img src={kranot} className="kranot logo" alt="kranot logo" />
            <img src={pais} className="pais logo" alt="pais logo" />
            <img src={"/icons/Hilma.svg"} className="hilma logo" alt="Hilma logo" />

        </div>
    </div>
}