import React from "react";
import '../../style/Product.scss';
import { Shapes } from "../Shapes";

interface VideoProps {
    video: string
}

export const Video: React.FC<VideoProps> = ({ video }) => {
    return <div className="video-container" style={{display: !video ? "none" : "relative"}}>
        <div className="blue-bg" />
        <Shapes className="rect index-s medium lemon" bottom="12%" left="5vw" right='initial' />
        <Shapes className="rect index-s medium blue" top="19%" left="5vw" right='initial' />
        <Shapes className="rect index-s medium blue" bottom='25%' right="8vw" />
        <Shapes className="rect index-s small blue" top="43%" left="-5px" right='initial' />
        <Shapes className="rect index-s small lemon" bottom="40%" right="-5px" />
        <div className="video">
            <iframe src={video} frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                height="100%" width="100%"
                title="video" />
        </div>
    </div>
}