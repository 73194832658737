import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Style from '../style/LeaveDetails.module.scss';
import Loading from '../components/Loading';
import { Fragment, useState } from 'react';
import { NotFound } from '../components/NotFound';

interface propState {
    url: string;
}

export const LeaveDetails = () => {
    const location = useLocation();
    const { name } = useParams<"name">()
    const { isLoading, data: src } = useQuery<string>(`iframe-url-${name}`, getUrl)
    const [iframeLoaded, setIframeLoaded] = useState(true);

    async function getUrl() {
        let url = (location.state as propState)?.url;
        if (url) return url;
        setIframeLoaded(false);
        const { data } = await axios.get('/api/product/get-iframe-url', { params: { name } });
        url = data;

        if (url.includes("forms.monday.com") && !url.includes('/embed/')) {
            /**
             * These lines of code have to be on 3 seperated lines:
             * split returns array, splice returns an empty array so they can't be connected.
             */
            let temp = url.split('/forms/');
            temp.splice(1, 0, '/forms/embed/');
            url = temp.join('');
        }
        return url;
    }

    if (src || isLoading)
        return <Fragment>
            {!iframeLoaded || isLoading ? <Loading width='100vw' /> : null}
            <iframe src={src} id='embed-iframe' style={{ display: iframeLoaded ? 'block' : 'none' }}
                className={Style.iframe} onLoad={() => setIframeLoaded(true)}></iframe>
        </Fragment>

    return <div className={Style.noContent}>
        <NotFound />
    </div>;
}