import { createI18nText } from "../createTextFunctions";

export const HomepageTexts = createI18nText({
    he: {
        partners: "שותפים",
        solutions4u: "פתרונות בשבילך",
        ourSolutions: "הפתרונות שלנו",
        productCats: "מוצרים בתחומי רווחה, חינוך ובריאות",
        storePoint: "חנות אפליקציות ופתרונות טכנולוגים",
        communicationPoint: "הפיתוחים שלנו מתאימים לזמן פנאי, ללימוד והעשרה, לתקשורת בין אישית- לכולם!",
        agesPoint: "מותאם לקהלי יעד שונים החל מהגיל הרך ועד מאה ועשרים",
        reviews: "מספרים עלינו",
        wroteAboutUs: "כתבו עלינו",
        NoArticles: "לא נמצאו כתבות",
        noReviews: "לא נמצאו ביקורות"
    },
    en: {
        partners: "Our Partners",
        solutions4u: "Solutions for You",
        ourSolutions: "Our Solutions",
        productCats: "Products in the fields of welfare, education and health",
        storePoint: "online store for apps and technological solutions",
        communicationPoint: "our developments are fitting for leasure and communication - it's for you!",
        agesPoint: "appropriate for different target audiences, from children to elderly",
        reviews: "What They Share About Us",
        wroteAboutUs: "What They Wrote About Us",
        NoArticles: "No articles found",
        noReviews: 'No Reviews found'
    }
})