import { Localize, useDirection, useTransformObject } from "../../translation/i18n";
import React, { useMemo } from "react";
import { ProductInfo } from "../../common/types/ProductInfo.type";
import { Link } from "react-router-dom";

interface ProductThumbnailProps {
    info: Localize<ProductInfo>
}

export const ProductThumbnail: React.FC<ProductThumbnailProps> = ({ info }) => {
    const transform = useTransformObject();
    const dir = useDirection();

    const translated = useMemo(() => {
        let _info = transform<ProductInfo>(info);
        return _info
    }, [info, transform])

    return (
        <Link to={`product/${info.enName}`} target="_blank">
            <div className={"thumbnail-container " + dir}>
                <div className="product-img">
                    <div className="hilma-tag">
                        {info.collaborate?.[0]?.['image'] ?
                            <div className='tag'>
                                <img src={info.collaborate?.[0]?.['image'] || ""} className="hilma-logo" />
                            </div> :
                            <h3>{info.collaborate?.[0]?.['enName'] || "--"}</h3>
                        }
                    </div>
                    {info.thumbnail?.image && <img src={info.thumbnail?.image} />}
                </div>
                <div className="product-info">
                    <div className="texts">

                        <div className="name">
                            {translated.name}
                        </div>
                        <div className="description">
                            {translated.thumbnail?.shortDescription}
                        </div>
                    </div>
                    <div className="categories">
                        {translated.thumbnail?.categories?.map((item: any) =>
                            <div className="category" key={item.name}>{item.name}</div>)}
                    </div>
                </div>
            </div>
        </Link>
    )
}