import { TableTexts } from './texts/Table';
import { NotFoundTexts } from './texts/NotFound';
import { AboutTexts } from './texts/About';
import { ReceiptsTexts } from './texts/Receipts';
import { PrivateAreaTexts } from './texts/PrivateArea';
import { PaymentTexts } from './texts/Payment.text';
import { ErrorsTexts } from './texts/Errors.text';
import { createI18nText, createI18n, Language } from './createTextFunctions'
import { createI18nHooksAndProvider, TranslatedI18n, Localize as LocalizeBase } from '@hilma/i18n';
import { createRef } from 'react';
import { HomepageTexts } from './texts/Homepage.text';
import { ProductTexts } from './texts/Product.texts';
import { LoginTexts } from './texts/Login.text';
import { OrgPruchaseText } from './texts/OrgPurchase.text';
import { NavbarText } from './texts/Navbar.text';
import { i18nErrorText } from './texts/I18nErrors';
import { ProfileText } from './texts/Profile.text';
import { DownloadTexts } from './texts/Download.text';
import { SupportText } from './texts/Support.text';


const i18n = createI18n({
    product: ProductTexts,
    homepage: HomepageTexts,
    login: LoginTexts,
    organizationPurchase: OrgPruchaseText,
    navbar: NavbarText,
    errors: ErrorsTexts,
    error: i18nErrorText,
    profile: ProfileText,
    payment: PaymentTexts,
    privateArea: PrivateAreaTexts,
    download: DownloadTexts,
    support: SupportText,
    receipts: ReceiptsTexts,
    about: AboutTexts,
    notFound: NotFoundTexts,
    table: TableTexts
}); // Put here all the texts you want to translate

export const {
    I18nProvider,
    contexts,
    useLanguage,
    createI18nHook,
    isLanguage,
    useChangeLanguage,
    useDirection,
    useLanguageEffect,
    useNoLanguagePathname,
    usePathLanguage,
    useSwitchedPath,
    useTransform,
    useTransformObject,
    createTranslateHook,
    createTranslateTextsHook,
    createTranslatedI18nHook,
    LanguageRoutes
} = createI18nHooksAndProvider(Language, i18n);


// export type Localize<T = any> = LocalizeBase<Language, T> //import {  Localize as LocalizeBase } from "@hilma/i18n";
export type Localize<T = any> = {
    [K in keyof T as K | `${Language}${Capitalize<string & K>}`]: T[K];
}
export type I18n = typeof i18n;
export const useI18n = createI18nHook<I18n>();
export const useTranslate = createTranslateHook<I18n>()
export const useTranslatedI18n = createTranslatedI18nHook<I18n>();
export const useTranslateTexts = createTranslateTextsHook<I18n>();
export const createI18nRef = () => createRef<TranslatedI18n<I18n>>();