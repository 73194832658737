import { FormInput, FormProvider, useAlert, useForm, useUpdateOnSubmit } from "@hilma/forms";
import { useDirection, useI18n, useLanguage, useTranslate } from "../translation/i18n";
import React, { MouseEventHandler, useEffect, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { useUser101 } from "../common/context/User101Context";
import { useStyles } from "./Profile";
import { provide } from "@hilma/tools";
import * as Yup from 'yup';
import axios from "axios";
import '../style/Generic.scss'
import './../style/ProfileNSupportForms.scss'

const SupportSchema = Yup.object().shape({
    mail: Yup.string()
        .email({ path: 'error.email' })
        .required({ path: 'error.required' }),
    subject: Yup.string()
        .max(100, { path: 'error.smallerThan', values: ['support.subject', 100] })
        .required({ path: 'error.required' }),
    content: Yup.string()
        .max(1000, { path: 'error.smallerThan', values: ['support.content', 1000] })
        .required({ path: 'error.required' })
})

const Support: React.FC<{}> = ({ }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const dir = useDirection();
    const text = useI18n(i18n => i18n.support);
    const save = useI18n(i18n => i18n.profile.save);
    const errorMessage = useI18n(i18n => i18n.errors.generalError);
    const { user } = useUser101();
    const form = useForm();
    const alert = useAlert();
    const classes = useStyles();

    useEffect(() => {
        user?.username && form.setFieldValue('mail', user?.username);
    }, [user])

    const sendSupport = async () => {
        setLoading(true);
        try {
            await axios.post('/api/mails/send-support-request', form.values);
            form.resetForm();
            user?.username && form.setFieldValue('mail', user?.username);
            alert(text.success, 'success');
        } catch (err: any) {
            alert(errorMessage)
        }
        setLoading(false)
    }

    useUpdateOnSubmit(() => {
        (async () => {
            await sendSupport();
        })()
    })

    return <div className={"support-container " + dir}>
        <h2 className="title support-title">{text.support}</h2>
        <div className="contact-us">{text.contactUs}</div>
        <FormInput classes={{ label: "label-x", container: "input-container", errorContainer: "error-msg" }} id="mail" description={text.mail} />
        <FormInput classes={{ label: "label-x", container: "input-container", errorContainer: "error-msg" }} id="subject" description={text.subject} />
        <FormInput classes={{ label: "label-x", container: "input-container content", errorContainer: "error-msg" }} id="content" description={text.content} multiline rows={5} />
        <Button classes={{ root: classes.sendButton, label: classes.buttonText }} onClick={form.handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>} children={!loading ? save : <CircularProgress size={'1.1rem'} thickness={5} />} className="send-button" />
    </div>
}

export default provide([
    FormProvider, {
        initialValues: { mail: '', subject: '', content: '' },
        rules: {},
        validationSchema: SupportSchema,
        customI18n: {
            usei18n: useI18n, useTranslate, useDirection, useLanguage: useLanguage as () => string
        }
    }
])(Support)