import { createI18nText } from "../createTextFunctions";

export const ErrorsTexts = createI18nText({
    he: {
        uName: "שם משתמש",
        pWord: "סיסמא",
        pWordVer: "אימות סיסמא",
        phone: "מספר טלפון",
        name: "שם",
        code: 'קוד',

        uNameRegex: "שם משתמש חייב להיות כתובת מייל תקינה",
        pWordRegex: "סיסמא חייבת להכיל חייב להכיל אות גדולה, אות קטנה, מספר ואחד מהסמנים המיוחדים הבאים- @$!%*-_#?&",
        pWordVerRegex: "אימות סיסמא חייבת להכיל חייבת להכיל אות גדולה, אות קטנה, מספר ואחד מהסמנים המיוחדים הבאים- @$!%*-_#?&",
        pWordVerSame: "אימות הסיסמא והסיסמא אינם תואמים",
        phoneRegex: "מספר טלפון יכול להכיל רק מספרים",
        nameRegex: "שם יכול להכיל רק אויות בעברית ובאנגלית, מקף וגרש",
        codeRegex: "קוד כניסה יכול להכיל רק מספרים",

        empty: (key: string): string => `${key || ""} חייב להכיל ערך`,
        maxValue: (key: string, max: number): string => `${key || ""} לא יכול להכיל יותר מ-${max || ""} תווים`,
        minValue: (key: string, min: number): string => `${key || ""} לא יכול להכיל פחות מ-${min || ""} תווים`,

        generalError: "אירעה שגיאה. אנא נסו שנית מאוחר יותר",
        noResults: 'לא נמצאו תוצאות',
        realPhone: "* שימו לב, מספר הטלפון חייב להיות המספר האמיתי שלך, מאחר והוא משמש בעת הכניסה למערכת.",
        israeliPhone: "מספר הטלפון שהוזן צריך להיות מספר ישראלי",
        uNameNotAvailable: "שם המשתמש כבר נמצא בשימוש",
        noOrgType: "חייב לבחור סוג ארגון",
        noProduct: "לא קיים פרוייקט עם שם כזה",

        PassDosentMatch: "הסיסמא אינה מתאימה",
        NoUsername: "שם המשתמש לא קיים",
        UserAlreadyExist: "שם המשתמש כבר נמצא במערכת",
        EmailNotVerified: "חשבון המייל שהוכנס לא מאומת",
        UserIsBlocked: "המשתמש נחסם, נסו שנית מאוחר יותר",
        MaxAttempts: "הגעת למספר הניסיונות המקסימליים",
        CodeHasExpired: "הקוד שהוכנס פג, עליך ליצור קוד חדש על מנת להתחבר",
        IncorrectCode: "הקוד שהוכנס שגוי",
        PasswordAndConfirmDoesntMatch: 'הסיסמא ואימות הסיסמא לא תואמים',



        bringData: "ארעה שגיאה בהבאת המידע",
        downloadError: "ארעה שגיאה, לא ניתן להוריד את הקובץ",
        refundError: 'ארעה שגיאה בנסיון לבצע החזר'
    },
    en: {
        uName: "User name",
        pWord: "Password",
        pWordVer: "Verify password",
        phone: "Phone number",
        name: "Name",
        code: 'Code',

        uNameRegex: "User name must be a valid email address",
        pWordRegex: "Password must contain an upper case letter, a lower case and one of the following characters- @$!%*-_#?&",
        pWordVerRegex: "Verify password must contain an upper case letter, a lower case and one of the following characters- @$!%*-_#?&",
        pWordVerSame: "Verify password is not the same a the password",
        phoneRegex: "Phone number can contain only digits",
        nameRegex: "Name can contain only Hebrew or English letters, hyphen and a apostrophe",
        codeRegex: "Code can contain only digits",

        empty: (key: string): string => `${key || ""} must contain a value`,
        maxValue: (key: string, max: number): string => `${key || ""} can't contain more than ${max || ""} characters`,
        minValue: (key: string, min: number): string => `${key || ""} can't contain more than ${min || ""} characters`,

        generalError: "An error accured. please try again later",
        noResults: 'No results found',
        realPhone: "* Please note that the phone number must be your real number as it is used when logging in.",
        israeliPhone: "Phone number should be an israeli phone",
        uNameNotAvailable: "User name already in use",
        noOrgType: "You have to chose an organization type",
        noProduct: "There isn't any project with that name",

        PassDosentMatch: "The password doesn't match",
        NoUsername: "The user doesn't exists",
        UserAlreadyExist: "The user is already exists",
        EmailNotVerified: "The email account wasn't verified",
        UserIsBlocked: "The user is blocked, try again later",
        MaxAttempts: "You have reached the maximum number of attempts",
        CodeHasExpired: "The code expired",
        IncorrectCode: "The code is incorrect",
        PasswordAndConfirmDoesntMatch: "Password and confirm password doesn't match ",



        bringData: "An error occurred while fetching the information",
        downloadError: "An error occurred, the file could not be downloaded",
        refundError: 'An error occurred while trying to make a refund'
    }
})