import { createI18nText } from "../createTextFunctions";

export const ReceiptsTexts = createI18nText({
    he: {
       receipts: 'קבלות',
       refunds: 'החזרים',
       asmachta: 'אסמכתא',
       paid: 'מחיר',
       suffix: 'כרטיס',
       date: 'תאריך עסקה',
       status: 'סטטוס עסקה',
       moreInfo: 'מידע נוסף',
       download: 'הורדת הקבלה',
       refund: 'הרכישה בוטלה',

       projectName: "שם הפרויקט",
       licenseName: "שם הרשיון",
       type: "סוג הרשיון",
       expireDate: "תאריך התפוגה",
       code: "קוד הרשיון",
       licensesNum: "מספר הרשיונות",


       institutional: "ארגוני",
       private: "פרטי",
       customized: "מוסדי",


       noData: "עוד אין קבלות זמינות",
    },
    en: {
       receipts: 'Receipts',
       refund: 'Purchase Canceled',
       asmachta: 'Asmachta',
       paid: 'Paid',
       suffix: 'Card Suffix',
       date: 'Deal Date',
       status: 'Deal Status',
       moreInfo: 'More Info',
       download: 'Download Receipt',

       projectName: "Project name",
       licenseName: "License name",
       type: "License type",
       expireDate: "Expire date",
       code: "License's code",
       licensesNum: "Number of licenses",


       institutional: "Institutional",
       private: "Private",
       customized: "Customized",

       noData: "No receipts available yet",
    }
})