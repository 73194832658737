import { useState } from 'react';
import { ReactComponent as Plant } from './../icons/plant.svg';
import { useI18n, useDirection } from '../translation/i18n';
import { BoughtProjectCard } from '../components/UserData/BoughtProjectCard';
import { usePathLanguage } from '../translation/i18n';
import { useAsyncEffect } from '@hilma/tools';
import axios from 'axios';
import { useAlert } from '@hilma/forms';
import { CircularProgress } from '@material-ui/core';
import { useIsPortrait } from '../common/context/IsPortraitContext';
import "../style/BoughtProjects.scss";
import '../style/OurSolutions.scss';

export interface myProjectInterface {
    id: number,
    projectName: string,
    expireDate: Date,
    licenseType: string,
    dateDiff: number,
    code: string,
    licenseName: string,
    licensesNumber: number,
    image: string
}

export const PrivateArea = () => {
    const [myProjects, setMyProjects] = useState<null | myProjectInterface[]>(null);
    const [loading, setLoading] = useState(true);
    const text = useI18n(i => i.privateArea);
    const errors = useI18n(i => i.errors)
    const direction = useDirection();
    const lang = usePathLanguage();
    const alert = useAlert()
    const isPortrait = useIsPortrait();

    useAsyncEffect(async () => {
        try {
            const { data } = await axios.get('/api/license/get-user-licenses')
            console.log('data: ', data);
            setMyProjects(data);
        } catch (err) {
            alert(errors.bringData)
        }
        setLoading(false);
    }, [])

    return (
        <div className={'bought-container ' + lang} style={{ direction }} >
            {/* some statistics? */}
            <h1 className='title'>{text.adminSystem}</h1>
            <div className='projects-cards' style={{ direction }}>
                {
                    loading ? <CircularProgress className='loading' /> :
                        !myProjects ? <p className="info-p">{errors.generalError}</p> : !myProjects.length ? <p className="info-p">{text.noData}</p> :
                            myProjects?.map?.(pro => <BoughtProjectCard key={pro.id} license={pro} />)
                }
            </div>
            {!isPortrait ? <div className="plant-container">
                <Plant className="plant" />
            </div> : null}
        </div>
    )
}
