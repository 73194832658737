import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useI18n, useLanguage, useDirection } from '../../translation/i18n';
import { useAsyncEffect, useAsyncState } from '@hilma/tools';
import axios, { AxiosRequestConfig } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSocket } from '@hilma/socket.io-react';
import GenericPopup from '../../components/GenericPopup';
import { useAlert } from '@hilma/forms';
import { Button } from '@material-ui/core';
import '../../style/Payment.scss'

export const ErrorTryAgainContext = createContext<(() => void) | null>(null);
export const StartPurchaseContext = createContext<(() => void) | null>(null);
export const LoadingContext = createContext<boolean>(false);
export const JwtContext = createContext<string | null>(null);

export const useErrorTryAgain = () => useContext(ErrorTryAgainContext)!;
export const useStartPurchase = () => useContext(StartPurchaseContext)!;
export const useLoading = () => useContext(LoadingContext)!;
export const useJwt = () => useContext(JwtContext)!;

const PurchaseProvider: React.FC = ({ children }) => {
	const [purchaseJwt, setPurchaseJwt, getPurchaseJwt] = useAsyncState<string | null>(null);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [userWithMissingDetails, setUserWithMissingDetails] = useState(false);
	const jwtIdInterceptor = useRef<number>()
	const { id: purchaseTypeId } = useParams<"id">();
	const navigate = useNavigate();
	const text = useI18n(i => i.profile);
	const socket = useSocket();
	const lang = useLanguage();
	const dir = useDirection();
	const alert = useAlert();

	useAsyncEffect(async () => {
		// listen to socket with the JWT token
		if (!purchaseJwt) return;
		connectToSocket();

		return async () => {
			socket.off('reconnect');
			socket.emit('jwt-deleted', purchaseJwt);
			jwtIdInterceptor.current && axios.interceptors.request.eject(jwtIdInterceptor.current);
		}
	}, [purchaseJwt]);

	const startPurchase = async () => {
		setLoading(true);
		try {
			if (purchaseJwt) {
				alert("רכישה בתהליכים...");
				return;
			}
			if (!lang) throw "NoLanguage";
			if (typeof purchaseTypeId !== "string") throw "NoPurchaseId"
			const { data } = await axios.post(`/api/purchase/start-purchase`, { purchaseTypeId });
			if (!data?.purchaseJwt) throw 'NoToken';
			if (!data?.iframeSrc) throw 'NoSrc'; // TODO - call to cancel the purchase?
			let newWindow = window.open("", "_blank");
			if (newWindow) newWindow.location = data.iframeSrc;
			console.log('is valid url', isValidUrl(data.iframeSrc));

			setLoading(false);
			setPurchaseJwt(data.purchaseJwt || "");
			return true;
		} catch (err: any) {
			setLoading(false);
			console.log('err: ', err);
			const message = err?.response?.data?.message || err?.message || err.data?.message || err.data?.error || "error"
			if (message === 'UserMissingData') {
				setOpen(true);
				setUserWithMissingDetails(true);
			} else if (message === "PURCHASE_IN_PROCESS") {
				alert("רכישה של המוצר כעת בביצוע");
			} else if (message === "USER_EXISTS") {
				alert("שם המשתמש כבר קיים בפרויקט המבוקש");
			} else {
				setOpen(false);
				setUserWithMissingDetails(false);
			}
			return false;
		}
	}

	const isValidUrl = (urlString: string) => {
		try {
			return Boolean(new URL(urlString));
		} catch (e) {
			return false;
		}
	}

	const connectToSocket = async () => {
		const jwtToken = await getPurchaseJwt();
		socket.emit('jwt-created', jwtToken);

		socket.on('reconnect', () => {
			socket.emit('jwt-created', jwtToken);
		});

		const interceptorId = axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
			if (jwtToken) {
				if (config?.headers) {
					config.headers.authorization = jwtToken;
				}
			}
			return config;
		});
		jwtIdInterceptor.current = interceptorId;
	}

	const onErrorTryAgainClick = async () => {
		setLoading(true);
		try {
			const { data: iframeSrc } = await axios.post(`/api/purchase/error-try-again`, { purchaseJwt });
			if (!iframeSrc) throw 'NoSrc'; // TODO - call to cancel the purchase?
			let newWindow = window.open("", "_blank");
			if (newWindow) newWindow.location = iframeSrc;
			console.log('is valid url', isValidUrl(iframeSrc), iframeSrc);
		} catch (err) {
			alert("errors.generalError");
			return;
		}
		setLoading(false);
	}

	const goToProfilePage = () => {
		navigate(`/${lang}/profile`)
	}

	return (
		<ErrorTryAgainContext.Provider value={onErrorTryAgainClick}>
			<StartPurchaseContext.Provider value={startPurchase}>
				<LoadingContext.Provider value={loading}>
					<JwtContext.Provider value={purchaseJwt} >
						<GenericPopup
							isOpen={open}
							title={text.isMissingHeader}
							text={text.isMissing}
							onClose={() => setOpen(false)}
							button={{ onClick: goToProfilePage, text: text.goToProfile }} />
						{userWithMissingDetails ? <div className='payment-messages'>
							<div className="content">
								<div className='text'>
									לא ניתן לבצע רכישה כל עוד חסרים פרטים.
								<br />
								אנא חזרו לעמוד הפרופיל והשלימו את הפרטים החסרים
							</div>
							</div>
							<div className='actiona'>
								<Button onClick={goToProfilePage}>בחזרה לעמוד הפרופיל</Button>
							</div>
						</div> : <div style={{ direction: dir }}>
								<div className="purchase-data-container">
									{children}
								</div>
							</div>}
					</JwtContext.Provider>
				</LoadingContext.Provider>
			</StartPurchaseContext.Provider>
		</ErrorTryAgainContext.Provider >
	);
}

export default PurchaseProvider;

export const useStyles = makeStyles({
	sendButton: {
		alignSelf: 'flex-end',
		marginRight: '32vw',
		backgroundColor: '#1a3650',
		border: '1px solid #1a3650',
		marginTop: '1vh',
		color: 'white',
		width: '8vw',
		'&:hover': {
			backgroundColor: 'white',
			color: '#1a3650',
		}
	},
	buttonText: {
		color: 'white',
		fontWeight: 700,
		fontSize: '1.1rem',
	}
})