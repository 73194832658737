import React, { useMemo } from "react";
import { Recommender } from "../../common/types";
import BlueProfile, { ReactComponent as Profile } from '../../icons/profile_blue.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { useIsPortrait } from '../../common/context/IsPortraitContext';
import { useI18n } from "../../translation/i18n";

interface RecommendersProps {
    recommenders: Recommender[]
}

export const Recommenders: React.FC<RecommendersProps> = ({ recommenders }) => {
    const isPortrait = useIsPortrait();
    const texts = useI18n(i => i.product)

    const displayNumber = useMemo(() => isPortrait ? 1.1 : 2.1, [isPortrait])

    return <div className={`recommenders-container ${recommenders.length === 0 && "empty"}`}>
        <div className="title">{texts.recommenders}</div>

        <Swiper
            pagination={true}
            modules={[Pagination]}
            slidesPerView={displayNumber}
        >
            {recommenders.map((rec, index) => {
                return <div className="rec" key={rec.image}>
                    <SwiperSlide >
                        <SingleRecommender recommender={rec} key={rec.image} />
                    </SwiperSlide>
                </div>
            })}
        </Swiper>
    </div>
}

const SingleRecommender: React.FC<{ recommender: Recommender }> = ({ recommender }) => {
    return <div className="rec-container">
        <div className="profile">
            {recommender.image?.length > 0 ? <img src={recommender.image}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = BlueProfile;
                }} /> : <Profile />}
        </div>
        <div className="text">
            <div className="content">{recommender.text}</div>
            <div className="from">{recommender.title}</div>
        </div>
        {/* <div className="background"></div> */}
    </div>
}