import { createI18nText } from "../createTextFunctions";

export const ProfileText = createI18nText({
    he: {
        name: 'שם מלא',
        username: 'דואר אלקטרוני',
        phone: 'מספר טלפון',
        password: 'סיסמא',
        orgName: 'שם הארגון',
        userType: 'סוג משתמש',
        replacePassword: 'החלפת סיסמא',
        save: 'שמירה',
        profile: 'פרופיל אישי',
        isMissing: 'חסרים פרטים אישיים שבלעדיהם אי אפשר לרכוש מוצרים דרך האתר!',
        isMissingHeader: 'חסרים פרטים אישיים',
        goToProfile: 'לפרופיל',
        close: 'סגירה'

    },
    en: {
        name: 'Full name',
        username: 'Email',
        phone: 'Phone',
        password: 'Password',
        orgName: 'Organization name',
        userType: 'Type of user',
        replacePassword: 'Replace',
        save: 'Save',
        profile: 'My profile',
        isMissing: 'Personal details are missing, without them you will not be able to buy products through the site!',
        isMissingHeader: 'Missing Personal Details',
        goToProfile: 'To Profile',
        close: 'close'
    }
})