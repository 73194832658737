import React from "react";
import { ProductFeature } from "../../common/types";
import { useI18n } from "../../translation/i18n";

interface ProductFeaturesProps {
    features: ProductFeature[]
}

export const ProductFeatures: React.FC<ProductFeaturesProps> = ({ features }) => {
    const texts = useI18n(i => i.product)

    return features.length ?
        <div className="product-features-container">

            <div className="title">{texts.includes}</div>

            {features.map(feature => {
                return <div className="feature" key={feature.icon}>
                    <div className="feature-icon">
                        <img src={`/icons/${feature.icon}.svg`} alt="" />
                    </div>
                    <div className="text">
                        <div className="name">{feature.title}</div>
                        <div className="description">{feature.text}</div>
                    </div>
                </div>
            })}

        </div> : null;
}