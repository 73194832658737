import { createI18nText } from "../createTextFunctions";

export const NavbarText = createI18nText({
    he: {
        products: "מוצרים",
        about: "אודותינו",
        tutorials: "הדרכות",
        support: "תמיכה",
        logout: "יציאה",
        profile: "פרופיל",
        privateArea: "איזור אישי",
        receipts: "קבלות",
        login: "כניסה",
        missing: "!חסרים פרטים, עליך להיכנס לפרופיל ולעדכן אותו",
        confirmLogout: "אישור התנתקות",
        RUSure: "האם את/ה בטוח/ה שברצונך להתנתק?",
        approve: "אישור"
    },
    en: {
        products: "Products",
        about: "About us",
        tutorials: "Tutorials",
        support: "Support",
        logout: "Logout",
        profile: "Profile",
        privateArea: "Personal area",
        receipts: "Receipts",
        login: "Login",
        missing: "Missing details, go to the profile page and update!",
        confirmLogout: "Logout confirmation",
        RUSure: "Are you sure you want to log out?",
        approve: "Approve"
    }
})