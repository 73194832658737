import React from "react";
import { useDirection, useI18n } from "../translation/i18n";
import { Button } from "@material-ui/core";
import { ReactComponent as Plant } from '../icons/plant.svg'
import '../style/Generic.scss'

interface AboutProps {
}
export const About: React.FC<AboutProps> = ({ }) => {
    const text = useI18n(i => i.about)
    const dir = useDirection();

    return <div className={"about-container " + dir}>
        <h2 className="title">{text.title}</h2>
        <div className="content">
            <p>{text.text1} <strong>{text.bold1}</strong></p>
            <p>{text.text2}</p>
            <p>{text.text3}</p>
        </div>
        <div className="buttons">
            <Button className="button">
                <a target="_blank" rel="noreferrer" href="https://www.hilma.tech/">{text.button}</a>
            </Button>
        </div>
        <Plant className='plant' />
    </div>
}
