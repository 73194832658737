import { Avatar, Badge, Button, Divider, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { useRef, useState, useEffect, useMemo, MouseEvent } from "react";
import { useDirection, useI18n, useLanguage } from "../translation/i18n"
import { ReactComponent as ProfileMobile } from '../icons/profile_white.svg';
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useIsAuthenticated, useLogout } from "@hilma/auth";
import { ReactComponent as Profile } from '../icons/profile_blue.svg';
import { ReactComponent as X } from '../icons/cancel_white.svg';
import { LanguageButton } from './LanguageButton';
import { useIsPortrait } from '../common/context/IsPortraitContext';
import { useUser101 } from "../common/context/User101Context";
import GenericPopup from './GenericPopup';
import ErrorIcon from '@material-ui/icons/Error';
import '../style/Navbar.scss'

export const Navbar = () => {
    const [dropList, setDropList] = useState<boolean>(false);
    const [navOpen, setNavOpen] = useState(false)
    const [open, setOpen] = useState<boolean>(false)
    const [show, setShow] = useState(true);

    const menu = useRef<HTMLDivElement>(null);
    const lang = useLanguage();
    const i18n = useI18n(i => i.navbar);
    const dir = useDirection();
    const isAuthenticated = useIsAuthenticated();
    const { user } = useUser101()
    const navigate = useNavigate()
    const location = useLocation()
    const logout = useLogout();
    const isPortrait = useIsPortrait();

    const links = useMemo(() => {
        return ([
        { text: i18n.products, to: `/${lang}` },
        { text: i18n.about, to: `/${lang}/about` },
        { text: i18n.tutorials, to: `/${lang}/tutorials` },
        { text: i18n.support, to: `/${lang}/support` },
        ...(isAuthenticated && isPortrait ? [
            { text: i18n.privateArea, to: `/${lang}/private-area` },
            { text: i18n.receipts, to: `/${lang}/receipts` },
        ] : [])
        ])
        // eslint-disable-next-line
    }, [lang, isAuthenticated, isPortrait])

    useEffect(() => {
        if (['login', 'register'].some(path => location.pathname.includes(`/${path}`)))
            setShow(false);
        else setShow(true);
    }, [location])

    const goToPage = (page: string, e?: MouseEvent) => {
        e?.stopPropagation();
        setNavOpen(false);
        setDropList(false);
        navigate(`/${lang}/${page}`);
    }

    const profileOrLogin = () => {
        if (!isAuthenticated) return navigate(`/${lang}/login`);
        !isPortrait ? setDropList(prev => !prev) : goToPage('profile');
    }

    const logoutAndCloseNavBar = async () => {
        setDropList(false);
        setNavOpen(false);
        navigate('/')
        await logout();
    }

    const isCurrentLocation = (link: string) => {
        return (link === location.pathname || link.concat('/') === location.pathname) || (location.pathname.includes(link) && link !== `/${lang}`);
    }

    return show ? <nav className={`nav ${dir} ${navOpen}`}>
        <div className="profile-lang-container">
            <div className="profile-icon-container" onClick={profileOrLogin} ref={menu}>

                {isAuthenticated ?
                    <IconButton className="profile-icon-container" >
                        <Badge
                            style={{ zIndex: 0 }}
                            overlap="circular"
                            badgeContent={user?.isMissing ?
                                <Tooltip title={i18n.missing}>
                                    <Avatar style={{ backgroundColor: '#f4f4f4', width: 19, height: 19 }}>
                                        <ErrorIcon className="missing" style={{ color: '#c55460', width: 23, height: 23 }} />
                                    </Avatar>
                                </Tooltip>
                                : null}>
                            <Avatar style={{ backgroundColor: isPortrait ? '#1a3650' : '#f4f4f4' }}>
                                {isPortrait ? <ProfileMobile /> : <Profile />}
                            </Avatar>
                        </Badge>
                    </IconButton> :
                    <Button variant="outlined" className={isPortrait ? "login-button" : ""}>{i18n.login}</Button>}

                {!isPortrait ?
                    <Menu
                        open={dropList}
                        dir={dir}
                        getContentAnchorEl={null}
                        anchorEl={menu.current}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <MenuItem className="dd-menu-item" onClick={(e) => goToPage('profile', e)}>{i18n.profile}</MenuItem>
                        <Divider />
                        <MenuItem className="dd-menu-item" onClick={(e) => goToPage('private-area', e)}>{i18n.privateArea}</MenuItem>
                        <Divider />
                        <MenuItem className="dd-menu-item" onClick={(e) => goToPage('receipts', e)}>{i18n.receipts}</MenuItem>
                        <Divider />
                        <MenuItem className="dd-menu-item" onClick={(e) => { e.stopPropagation(); setOpen(true) }}>{i18n.logout}</MenuItem>
                    </Menu> : null
                }
            </div>
            {isPortrait ? <X onClick={() => setNavOpen(false)} /> : null}
            <LanguageButton className={isPortrait ? "lang-portrait" : ""} />
        </div>
        <div className="links">
            {links.map((link, i) => <Link key={link.text} to={link.to}><div className={`nav-option ${isCurrentLocation(link.to)}`} onClick={() => { setNavOpen(false) }}>{link.text}</div></Link>)}
            {isPortrait && isAuthenticated ? <>
                <hr className='link-break' />
                <div className={`nav-option`} onClick={(e) => { e.stopPropagation(); setOpen(true) }}>{i18n.logout}</div>
            </> : null}
        </div>
        {!navOpen ?
            < Link to='/'>
                <img src="/icons/impact-place-logo.svg" alt="logo" />
            </Link> : null
        }

        <div className="hamburger" onClick={() => setNavOpen(true)}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
        </div>

        <GenericPopup
            isOpen={open}
            title={i18n.confirmLogout}
            text={i18n.RUSure}
            button={{ text: i18n.approve, onClick: logoutAndCloseNavBar }}
            onClose={() => setOpen(false)} />
    </nav > :
        null
}