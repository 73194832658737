import { ReactComponent as Left } from '../../icons/left.svg';
import { useDirection, useI18n } from "../../translation/i18n";
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useState } from "react";
import { useAsyncEffect } from "@hilma/tools";
import { Navigation } from 'swiper';
import { useAlert } from '@hilma/forms';
import axios from "axios";
import "swiper/css/navigation";
import "swiper/css/bundle";
import 'swiper/css';
import '../../style/Slider.scss'
import '../../style/Generic.scss';
import '../../style/NewsArticles.scss';
import { CircularProgress } from '@material-ui/core';

export const NewsArticles: React.FC = ({ }) => {
    const [loading, setLoading] = useState(true);
    const [articals, setArticals] = useState<string[]>([]);

    const text = useI18n(i => i.homepage)
    const errors = useI18n(i => i.errors)
    const dir = useDirection();
    const alert = useAlert();

    useAsyncEffect(async () => {
        try {
            let { data: res } = await axios.get('/api/info/get-videos')
            setArticals(res);
        } catch (err) {
            alert(errors.bringData);
        }
        setLoading(false);
    }, [])

    return <div className={"articles-container " + dir}>
        <div className="title">
            {text.wroteAboutUs}
        </div>
        {loading ? <div style={{ width: "100%", textAlign: "center", marginTop: "5vh" }}><CircularProgress style={{ margin: "auto" }} /></div> : articals.length ? <div className="slide-container">
            <Swiper navigation={{
                nextEl: '.swiper-button-unique.next',
                prevEl: '.swiper-button-unique.prev'
            }} modules={[Navigation]} slidesPerView={'auto'} spaceBetween={20}>
                {articals.map((src, inx) =>
                    <SwiperSlide key={src + inx} style={{ width: '50vw', margin: 0 }}>
                        <iframe src={src} frameBorder="0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                            height="100%" width="100%"
                            style={{
                                width: "50vw"
                            }}
                            title="video" />
                    </SwiperSlide>
                )}
            </Swiper>
            <div className="swiper-button-unique next"><Left /></div>
            <div className="swiper-button-unique prev"><Left /></div>
        </div> : <div className='error'>{text.NoArticles}</div>}
    </div>
}