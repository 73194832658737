import React from "react";
import { useNavigate } from "react-router-dom";
import '../style/NotFound.scss';
import { useDirection, useI18n } from "../translation/i18n";


export const NotFound: React.FC<{}> = () => {
    const navigate = useNavigate();
    const direction = useDirection();
    const text = useI18n(i => i.notFound);

    const backToHome = () => navigate('/');

    return <div className='not-found' style={{ direction }}>
        <img src='/error-404.png' alt='NOT_FOUND' />
        <h2>{text.pageDoesNotExist}</h2>
        <p>{text.back} <span onClick={backToHome} className='link'>{text.here}</span></p>
    </div>
}
