import { InfoStrip } from "../../common/types";
import React from "react";
import '../../style/ProductComps/InfoStrip.scss';

interface ProductInfoStripProps {
    info?: InfoStrip;
    color?: string | undefined;
    name?: string | undefined
}

export const ProductInfoStrip: React.FC<ProductInfoStripProps> = ({ info, color, name }) => {
    const txt_html = info?.text?.replaceAll('<p>', '<p class="editor-paragraph">')

    return <div className="info-strip">
        <div className='text-container'>
            <h1 className="name" style={{ color }}>{name}</h1>
            <div className="text">
                <p className="editor-title">{(info?.title || "")}</p>
            </div>
            <div className="text" dangerouslySetInnerHTML={{ __html: txt_html || '' }}></div>
        </div>

        <div className="img-container">
            {info?.image && <img src={info.image} alt="info" />}
        </div>
    </div>
}