import { useI18n, useDirection, useLanguage } from '../../translation/i18n';
import { myProjectInterface } from '../../pages/PrivateArea';
import React, { useState } from 'react';
import { Tooltip, Zoom } from '@material-ui/core';
import GenericPopup from '../GenericPopup';
import InfoIcon from '@material-ui/icons/Info'
import TabIcon from '@material-ui/icons/Tab';
import axios from 'axios';
import { useAlert } from '@hilma/forms';
import { useIsPortrait } from '../../common/context/IsPortraitContext';
// import './../../style/OurSolutions.scss';

interface propsInterface {
    license: myProjectInterface
}

interface licenseExtras {
    user: { username: string },
    activeLicenses: number
}

export const BoughtProjectCard: React.FC<propsInterface> = ({ license }: propsInterface) => {
    const [open, setOpen] = useState(false);
    const [activeNum, setActiveNum] = useState<number | "--">(0);

    const text = useI18n(i => i.privateArea);
    const errors = useI18n(i => i.errors)
    const direction = useDirection();
    const alert = useAlert();
    const isPortrait = useIsPortrait();
    const lang = useLanguage()

    const remoteLogin = async (_e: any) => {
        try {
            const { data } = await axios.post('/api/license/remote-project-login', {
                licenseId: license.id
            });
            let newWindow = window.open("", "_blank")
            if (isValidUrl(data) && newWindow) return newWindow.location = data;
            else alert(text.cantTransport)
        } catch (err: any) {
            alert(text[err?.data?.error as keyof typeof text] || text.dataError)
        }
    }

    const isValidUrl = (urlString: string) => {
        try {
            return Boolean(new URL(urlString));
        } catch (e) {
            return false;
        }
    }

    const openPopup = async () => {
        try {
            changeOpenState();
            const { data } = await axios.get<licenseExtras>(`/api/license/get-license-remote-details/${license.id}`);
            console.log('data: ', data);
            setActiveNum(data.activeLicenses);
        } catch (err) {
            console.log('err: ', err);
            setActiveNum("--");
        }
    }

    const changeOpenState = () => {
        setOpen(prev => !prev);
    }

    return (
        <div className="thumbnail-container" key={license.id} style={{ direction }}>
            <div className="product-img">
                <Tooltip arrow={true} TransitionComponent={Zoom} title={text.redirectToSite}>
                    <div className="remote-login">
                        <TabIcon onClick={remoteLogin} />
                    </div>
                </Tooltip>
                <Tooltip arrow={true} TransitionComponent={Zoom} title={text.moreInfo}>
                    <div className="more-info">
                        <InfoIcon onClick={openPopup} />
                    </div>
                </Tooltip>
                <img src={license.image} alt={`${license.projectName} - thumbnail`} />
                {license.dateDiff <= 30 ? <div className="hilma-tag">
                    <span className="tag-info">
                        {
                            license.dateDiff < 0 ? text.expired
                                : license.dateDiff <= 30 ? `${license.dateDiff} ${text.daysLeft}` : ""
                        }
                    </span>
                    <img src="/icons/clock.svg" className='clock' alt="clock" />
                </div> : null}
            </div>
            <div className="product-info" style={{ direction }}>
                <span className="name" id="project-name">{license.projectName}</span>
                <span className="description">{text[license.licenseType as keyof typeof text]}</span>
                <span>{license.expireDate}</span>
            </div>

            <GenericPopup
                isOpen={open}
                imagePath=""
                title={text.title}
                fullScreen={isPortrait}
                onClose={changeOpenState} >
                <div className="info-popup">
                    {['licenseName', 'licenseType', 'expireDate', 'code', 'licensesNumber', 'active'].map(att => {
                        return <span key={att}>
                            <h4>{text[att as keyof typeof text]}</h4>
                            {att === "licenseType" ? <p>{text[license.licenseType as keyof typeof text]}</p> :
                                <p>{att === "active" ? activeNum : license[att as keyof myProjectInterface]}</p>}
                        </span>
                    })}
                </div>
            </GenericPopup>
        </div>
    )
}
