import { createI18nText } from "../createTextFunctions";

export const AboutTexts = createI18nText({
    he: {
        title: "הילמה - הייטק למען החברה",
        text1: 'הילמ"ה- הייטק למען החברה, היא חברה לתועלת הציבור וללא מטרות רווח, שהוקמה ב-2018 על ידי בכירים בעולם ההיי-טק הישראלי, המבקשים',
        bold1: 'להפוך את ישראל למובילה עולמית בתחום חדשנות האימפקט, על מנת לתת מענה לאתגרים ציבוריים וחברתיים. ',
        text2: 'הילמ"ה הוקמה בהשראת יחידת 8200 הצה"לית, והיא מכשירה דור של צעירות וצעירים מוכשרים ושאפתנים לפיתוח פתרונות טכנולוגיים-חברתיים פורצי דרך לתועלת החברה. ',
        text3: 'הפתרונות של הילמ"ה מתוכננים בהתאמה מלאה לצורכי החינוך, הרווחה והבריאות, ומתקבלים באהדה רבה על ידי לקוחות, קולגות ושותפים לדרך.',
        button: 'לביקור באתר הילמ"ה'
    },
    en: {
        title: "Hilma- high tech for impact",
        text1: 'Hilma - Tech for Impact is a non-profit company that is fully dedicated to helping the public. It was established in 2018 by Israeli High-tech executives whose dreams are to',
        bold1: 'form Israel into the leader of impactful innovation addressing public and social challenges.',
        text2: "Hilma’s establishment was inspired by the IDF Unit 8200 and trains a generation of talented and ambitious young men and women to develop groundbreaking technological-social solutions with societal benefit.",
        text3: 'The solutions of Hilma are designed in full accordance with the needs of education, welfare, and health and are received after great anticipation by customers, colleagues, and partners..',
        button: "Visit Hilma's site"
    }
})