import { Localize, useDirection, useI18n, useTransformObject } from "../translation/i18n";
import React, { useEffect, useRef, useState } from "react";
import { Tutorial as TutorialType } from "../common/types";
import { CircularProgress } from "@material-ui/core";
import { useAsyncEffect } from "@hilma/tools";
import { Tutorial } from "../components/Tutorial";
import { useAlert } from "@hilma/forms";
import axios from "axios";
import '../style/Generic.scss'
import '../style/TutorialsPage.scss'
import { ReactComponent as Plant } from '../icons/plant.svg';

export const TutorialsPage: React.FC<{}> = () => {
    const [tutorials, setTutorials] = useState<TutorialType[] | undefined>(undefined);

    const transform = useTransformObject()
    const _tutorials = useRef<Localize<TutorialType>[]>([]);
    const texts = useI18n(i => i.product)
    const errors = useI18n(i => i.errors)
    const dir = useDirection();
    const alert = useAlert()

    useAsyncEffect(async () => {
        try {
            const { data } = await axios.get<Localize<TutorialType>[]>('/api/tutorials/all')
            _tutorials.current = data;
            setTutorials(data.map(item => transform(item)));
        } catch (err) {
            alert(errors.bringData);
        }
    }, [])

    useEffect(() => {
        setTutorials(_tutorials.current.map(item => transform(item)));
    }, [texts])

    return <div className={"tutorials-page-container " + dir}>
        <div className="title">
            {texts.upcoming_tutorials}
        </div>
        <div className="tutorials-container">
            {!tutorials ? <CircularProgress /> : !tutorials?.length ? <p className="no-tuts">{texts.noTutorials}</p> : tutorials?.map(item =>
                <Tutorial tutorial={item} key={item._id} />
            )}
        </div>
        <Plant className="plant" />
    </div>
}