import { AuthProvider, PrivateRoute, PublicOnlyRoute } from '@hilma/auth';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { I18nProvider, useLanguage } from './translation/i18n';
import { OrganizationPurchase } from './pages/OrganizationPruchase';
import { Alert, AlertProvider } from '@hilma/forms';
import { IsPortraitProvider } from './common/context/IsPortraitContext';
import { TWO_FACTOR_COOKIE } from './common/consts/two-factor.const';
import React, { Fragment } from 'react';
import { User101Provider } from './common/context/User101Context';
import { SocketProvider } from '@hilma/socket.io-react';
import { ThemeProvider } from '@material-ui/core';
import { TutorialsPage } from './pages/TutorialsPage';
import { LeaveDetails } from './pages/LeaveDetails';
import { DownloadPage } from './pages/DownloadPage';
import { PrivateArea } from './pages/PrivateArea';
import ResetPassword from './pages/ResetPassword';
import { HomePage } from './pages/HomePage';
import { Receipts } from './pages/Receipts';
import { NotFound } from './components/NotFound';
import { muiTheme } from './common/consts/mui-theme';
import { provide } from '@hilma/tools';
import { Product } from './pages/Product';
import { Navbar } from './components/Navbar';
import { About } from './pages/Abouts';
import Register from './pages/Register';
import Payments from './pages/Paymants';
import Support from './pages/Support';
import Profile from './pages/Profile';
import Login from './pages/Login';
import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';

function App() {
  const lang = useLanguage();
  const queryClient = new QueryClient()

  return (
    <Fragment>
      <QueryClientProvider client={queryClient}>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="product/:name" element={<Product />} />
          <Route path="product/:name/leaveDetails" element={<LeaveDetails />} />
          <Route path="organization-purchase/:name" element={<OrganizationPurchase />} />
          <Route path="payment/:id" element={<Payments />} />
          <Route path="about" element={<About />} />
          <Route path="tutorials" element={<TutorialsPage />} />
          <Route path="support" element={<Support />} />
          <Route path="download/:name" element={<DownloadPage />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="profile" element={<PrivateRoute componentName="Profile" redirectPath={`/${lang}/login`} component={<Profile />} />} />
          <Route path="receipts" element={<PrivateRoute componentName="Receipts" redirectPath={`/${lang}/login`} component={<Receipts />} />} />
          <Route path="private-area" element={<PrivateRoute componentName="PrivateArea" redirectPath={`/${lang}/login`} component={<PrivateArea />} />} />
          <Route path='/reset-password' element={<PublicOnlyRoute component={ResetPassword} componentName='ResetPassword' />} />
          <Route path="*" element={<NotFound />} />

        </Routes>
        <Alert />
      </QueryClientProvider>
    </Fragment>
  );
}

export default provide(
  Router,
  [
    AuthProvider,
    {
      logoutOnUnauthorized: true,
      accessTokenCookie: 'kloklo',
      twoFactorCookie: TWO_FACTOR_COOKIE
    }
  ],
  AlertProvider,
  [
    SocketProvider,
    {
      uri: 'https://impact.hilma.tech/' /* 'http://localhost:8080' */,
      options: {
        transports: ['websocket'],
        query: { accessToken: 'kloklo' }
      }
    }
  ],
  [ThemeProvider, { theme: muiTheme }],
  [I18nProvider, { router: true }],
  IsPortraitProvider,
  User101Provider,
)(App);
