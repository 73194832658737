import { Localize, useDirection, useI18n, useTransformObject } from "../translation/i18n";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { ReactComponent as BottomLeaf } from '../icons/reviews/bottom-leaf.svg';
import { ReactComponent as TopLeaf } from '../icons/reviews/top-leaf.svg';
import { ReactComponent as WebGirl } from '../icons/reviews/web_girl.svg';
import { ReactComponent as Plant } from '../icons/plant.svg';
import { ReactComponent as Web } from '../icons/reviews/Web.svg';
import { Link, useParams } from "react-router-dom";
import { ProductLinks } from "../common/types";
import { useAsyncEffect } from "@hilma/tools";
import { fillInTemplate } from "../common/functions/fillInTemplate";
import { ButtonBase } from "@material-ui/core";
import GenericPopup from "../components/GenericPopup";
import QRCode from "react-qr-code";
import axios from "axios";
import { useAlert } from "@hilma/forms";
import { DownloadAppButton } from "../components/product-page/DownloadAppButton";
import { useIsPortrait } from "../common/context/IsPortraitContext";
import '../style/DownloadPage.scss'

export type AppType = 'android' | 'ios';
export type PlatformType = 'web' | AppType;

interface LinkInfo {
    name: string;
    download: ProductLinks,
    thumbnail: { shortDescription: string }

}

export const DownloadPage: React.FC<{}> = ({ }) => {
    const [linksInfo, setLinksInfo] = useState<LinkInfo>();
    const [qrPopup, setQrPopup] = useState<PlatformType | null>(null);
    const productData = useRef<Localize<LinkInfo>>()
    const { name } = useParams<"name">();
    const transform = useTransformObject();
    const text = useI18n(i => i.download);
    const errors = useI18n(i => i.errors)
    const dir = useDirection();
    const alert = useAlert();
    const isPortrait = useIsPortrait();

    useAsyncEffect(async () => {
        try {
            const { data } = await axios.get<Localize<LinkInfo>>('/api/product/get-proudct-extra', { params: { name, type: "links" } });
            productData.current = data;
            setLinksInfo(transform(data));
        } catch (err) {
            alert(errors.bringData);
        }
    }, [])

    useEffect(() => {
        productData.current && setLinksInfo(transform(productData.current));
    }, [transform])

    const openApp = (appType: PlatformType) => {
        linksInfo?.download[appType] && window.open(linksInfo?.download[appType], '_blank')
    }

    const openQRPopup = (platformType: AppType) => {
        setQrPopup(platformType);
    }

    return <div className={"download-page-container " + dir}>
        {!isPortrait ?
            <><div className="side-img">
                <Web className="web" />
                <WebGirl className="web-girl" />
                <TopLeaf className="top-leaf" />
                <BottomLeaf className="bottom-leaf" />
            </div>
                <Plant className="plant" /></> : null}

        <div className="content">
            <h1 className="name">{linksInfo?.name || name}</h1>
            <div className="slogen">{linksInfo?.thumbnail.shortDescription}</div>
            <div className="todownload-container">
                <div className="text">{text.toDownload}</div>
                <div className="buttons">
                    <DownloadAppButton type={'android'} link={linksInfo?.download?.['android'] || ""} openApp={openApp} openQRPopup={openQRPopup} />
                    <DownloadAppButton type={'ios'} link={linksInfo?.download?.['ios'] || ""} openApp={openApp} openQRPopup={openQRPopup} />
                </div>
            </div>
            <div className="app-link-text">{fillInTemplate(text.websiteText || '', { name: linksInfo?.name || name })}</div>
            <ButtonBase disabled={!linksInfo?.download?.['web']} onClick={() => openApp('web')} className="to-website">{`${text.goToWebsite} ${linksInfo?.name || name}`}</ButtonBase>
        </div>
        <GenericPopup onClose={() => setQrPopup(null)} isOpen={!!qrPopup} title={fillInTemplate(text.qrCodePopupText || '', { name: linksInfo?.name || name, platform: qrPopup })}>
            <QRCode style={{ margin: '0 9vw' }} value={linksInfo?.download?.['android'] || ''} />
        </GenericPopup>
    </div>
}