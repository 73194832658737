import React from "react";
import { Tutorial as TutorialType } from "../../common/types";
import { useI18n } from "../../translation/i18n";
import { Tutorial } from "../Tutorial";
import '../../style/Generic.scss'

interface UpcomingTutorialsProps {
    tutorials: TutorialType[]
}
export const UpcomingTutorials: React.FC<UpcomingTutorialsProps> = ({ tutorials }) => {
    const upcomingText = useI18n(i => i.product)
    tutorials = [];
    return tutorials.length ? <div className="tutorials-container">
        <div className="title">{upcomingText.upcoming_tutorials}</div>
        <div className="tutorials">
            {tutorials?.map((tut, index) => <Tutorial tutorial={tut} key={index} />)}
            {!tutorials?.length ? <p>{upcomingText.noTutorials}</p> : null}
        </div>
    </div> : null
}