import { useDirection, useI18n, usePathLanguage, useTranslate, useLanguage } from "../translation/i18n";
import React, { useMemo, useEffect } from "react";
import { useNavigate, useSearchParams, Link, useLocation, Location } from 'react-router-dom';
import { ReactComponent as People } from '../icons/home_people.svg';
import { ReactComponent as TallPlant } from '../icons/reviews/plant.svg';
import { UserTypes, USER_TYPE_OPTIONS } from "../common/consts/orgTypeOptions";
import { useUser101 } from "../common/context/User101Context";
import { FormInput, FormProvider, useAlert, useForm } from "@hilma/forms";
import { useState } from "react";
import axios from 'axios';
import { useIsPortrait } from "../common/context/IsPortraitContext";
import '../style/Login.scss';
import { Shapes } from "../components/Shapes";
import { Home } from "@material-ui/icons";
import { provide } from "@hilma/tools";
import RadioGroupInput from "../components/RadioGroupInput";
import * as Yup from 'yup';
import { HEBREW_REGEX, NAME_REGEX, PASSWORD_REGEX } from "../common/consts/regex.consts";
import { FormPasswordInput } from "../components/FormPasswordInput";

interface StateInterface {
    productName?: string
}

const fieldsFormat = { username: 0, name: 1, phone: 1, userType: 2, orgName: 2, password: 3, confirmPassword: 3 };

const Register: React.FC<{}> = ({ }) => {
    const [page, setPage] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [maxPageCurrentRegiser, setMaxPageCurrentRegiser] = useState<number>(0);

    const { user } = useUser101();
    const path = usePathLanguage();
    const [search] = useSearchParams();
    const alert = useAlert();
    const form = useForm()
    const navigate = useNavigate();
    const { state }: Location = useLocation();
    const dir = useDirection();
    const text = useI18n(i => i.login);
    const errorTexts = useI18n(i => i.errors);
    const isPortrait = useIsPortrait();

    const userTypes = useMemo(() => USER_TYPE_OPTIONS.map(op => ({ ...op, value: text[op.id] })), [text]);

    const productName = useMemo(() => {
        const name = search.get('productName');
        return (state as StateInterface)?.productName || name;
    }, [state, search]);

    useEffect(() => {
        if (search.get("message") === "APPROVED") {
            alert("משתמש אושר בהצלחה. כעת ניתן להתחבר לאתר", 'success');
        }
    }, [search])

    useEffect(() => {
        setMaxPageCurrentRegiser(prev => {
            return Math.max(page, prev);
        })
        if (page === 2) {
            form.setFieldTouched('userType')
        }
    }, [page])

    useEffect(() => {
        const error = search.get('error');
        if (error) alert(String(errorTexts[error as keyof typeof errorTexts] || errorTexts['generalError']))
    }, [])

    const canSend = useMemo(() => {
        let canSend = true;
        if (loading) return false;
        for (let key in fieldsFormat) {
            if (fieldsFormat[key as keyof typeof fieldsFormat] !== page || (page === 3 && user?.isGoogleUser)) continue;
            canSend &&= !form.errors[key];
        }
        return canSend;
    }, [form.errors, page, loading]);

    const registerPage = useMemo(() => {
        return user ? 2 : 3
    }, [user])

    useEffect(() => {
        if (!user) return;
        if (!user?.isMissing) {
            navigate('/');
        } else {
            form.setFieldValue('username', user.username);
            form.setFieldValue('name', user.name);
            if (user.username) setPage(1);
        }

        setLoading(false);
    }, [user]);

    const sendValidate = (byPage: boolean = false) => {
        setLoading(true);
        if (((byPage && page === 3) || !byPage) && !user && form.values.password !== form.values.confirmPassword) {
            form.setFieldError('confirmPassword', errorTexts.pWordVerSame)
            setLoading(false);
            return false;
        }
        for (let key in fieldsFormat) {
            if ((byPage && fieldsFormat[key as keyof typeof fieldsFormat] !== page)) continue;
            form.validateField(key)
        }
        setLoading(false);
        return true;
    }

    const checkUserNameAvailable = async () => {
        try {
            const { data } = await axios.get(`/api/101/user/check-username-available?username=${form.values.username}`);
            if (!data) {
                form.setFieldError('username', 'uNameNotAvailable');
            }
            return !!data;
        } catch (err) {
            alert(errorTexts.generalError);
            return;
        }
    }

    const nextPage = async () => {
        if (!canSend || !sendValidate(true)) return;
        if (page === 0) {
            const canContinue = await checkUserNameAvailable();
            if (!canContinue) return;
        }
        setPage(prev => prev >= registerPage ? prev : prev + 1);
    }

    const register = async () => {
        if (!sendValidate()) return;
        setLoading(true);
        try {
            await axios.post('/api/101/user/register-user101', {
                ...form.values,
                password: form.values.password || null,
                verPassword: form.values.confirmPassword || null,
                phone: form.values.phone.replace("0", "+972"),
                productName
            })
            setPage(prev => prev + 1);
        } catch (err: any) {
            alert(err?.data?.message.includes('INVALID_PHONE') ? errorTexts.israeliPhone : errorTexts.generalError);
        }
        setLoading(false);
    }

    const goToHomePage = () => {
        if (productName) {
            navigate(`/${path}/organization-purchase/${productName}`);
            return;
        }
        navigate('/');

    }

    const registerWithGoogle = () => {
        window.location.href = `${window.location.origin.replace(":3000", ":8080")}/google/register?lang=${path}&productName=${productName || ""}`
    }

    const changePage = (pageNum: number) => {
        if (maxPageCurrentRegiser < pageNum) return;
        setPage(pageNum);
    }

    return <div className={"login-container sign-up " + dir}>
        <div className="bottom-blue"></div>
        {!isPortrait ?
            <><div className="right-design">
                <TallPlant className="tall-plant" />
                <Shapes className='rect medium opacity-s purple' right="2vw" bottom="45%" top="initial" />
                <Shapes className='rect medium opacity-s neon-carrot' right="4vw" bottom="61%" top="initial" />
            </div>
                <div className="side-image">
                    <People className="people" />
                    <Shapes className='rect medium opacity-s purple' right="57vw" bottom="7%" top="initial" />
                    <Shapes className='rect medium opacity-s neon-carrot' right="50vw" bottom="2%" top="initial" />

                </div></> : null}
        <div className="side-text">
            {page !== registerPage + 1 ?
                <div className="progress">
                    {([...Array(registerPage + 1)].map((_x, index) => (
                        <button aria-disabled={loading} onClick={() => changePage(index)} key={index} className={`page-dot ${maxPageCurrentRegiser >= index} ${page === index ? 'current' : ''}`} />
                    )))}
                </div> : null}
            <h2 className="title">
                {text["page".concat(page === registerPage + 1 ? (user?.isGoogleUser ? "GoogleDone" : "Done") : String(page)).concat("Top") as keyof typeof text]}
                <p className="subtitle"> {text["page".concat(page === registerPage + 1 ? (user?.isGoogleUser ? "GoogleDone" : "Done") : String(page)).concat("Low") as keyof typeof text]}</p>
            </h2>
            <div className="inputs">
                {
                    Object.entries(fieldsFormat).map(([name, value]) => (
                        value === page && (!(page === 3 && user?.isGoogleUser)) ?
                            ['password', 'confirmPassword'].includes(name) ?
                                <FormPasswordInput key={name} disabled={loading} id={name} description={text[name as keyof typeof text] || ""} /> :
                                name === "userType" ?
                                    <RadioGroupInput disabled={loading} id={name} description={''} options={userTypes} /> :
                                    <FormInput key={name} disabled={loading} id={name} description={text[name as keyof typeof text] || ""} />
                            : null
                    ))
                }

            </div>

            <button aria-disabled={loading}
                className={"connect " + (canSend)}
                disabled={!canSend}
                onClick={registerPage === page ? register : (page === registerPage + 1 ? goToHomePage : nextPage)}>
                {registerPage === page ? text["signUpButton"] : (page === registerPage + 1 ? text['homePage'] : text["next"])}
            </button>

            {page === 0 ? <>
                <hr />
                <button aria-disabled={loading} className={"connect google"} onClick={registerWithGoogle}>
                    Google
                    <img src="/icons/google.svg" className="google-g-logo" />
                </button>
            </> : null}
            {page === registerPage + 1 ? null : <div className='bottom-buttons'>
                <button aria-disabled={loading} className="no-account-btn" onClick={() => navigate(`/${path}/login`, { state: state || {} })}>{text.haveAccount}</button>
            </div>}


        </div>
        <Link to="/">
            <img className="hilma-logo" src="/icons/Hilma.svg" />
        </Link>
    </div>
}

const RegisterSchema = Yup.object().shape({
    username: Yup.string()
        .required({ path: 'error.required' })
        .email({ path: 'errors.uNameRegex' }),

    name: Yup.string()
        .required({ path: 'error.required' })
        .matches(NAME_REGEX, { message: { path: 'errors.nameRegex' } }),
    phone: Yup.string()
        .required({ path: 'error.required' })
        .matches(/^[0-9]{9,16}$/, { message: { path: 'error.phoneRegex' } }),

    orgName: Yup.string()
        .required()
        .matches(HEBREW_REGEX, { message: { path: 'errors.nameRegex' } }),

    userType: Yup.string()
        .oneOf(Object.values(UserTypes))
        .required({ path: 'error.required' }),

    password: Yup.string()
        .required({ path: 'error.required' })
        .matches(PASSWORD_REGEX, { message: { path: 'errors.pWordRegex' } }),

    confirmPassword: Yup.string()
        .required({ path: 'error.required' })
        .matches(PASSWORD_REGEX, { message: { path: 'errors.pWordRegex' } })
})

export default provide([FormProvider, {
    initialValues: { username: '', name: '', phone: '', userType: '', orgName: '', password: '', confirmPassword: '' },
    validationSchema: RegisterSchema,
    customI18n: {
        usei18n: useI18n, useTranslate, useDirection, useLanguage: useLanguage as () => string
    }
}])(Register)
