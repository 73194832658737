import { createI18nText } from "../createTextFunctions";

export const ProductTexts = createI18nText({
    he: {
        org_purchase: "אפשרויות רכישה לארגונים",
        upcoming_tutorials: "הדרכות קרובות:",
        includes: "מה המערכת כוללת?",
        download: "להורדה לחצו כאן",
        goToWebsite: "למעבר לאתר לחצו כאן",
        leaveDetails: 'להשארת פרטים לחצו כאן',
        signup: "להרשמה",
        developedBy: "המוצר פותח על ידי",
        hilma: 'הילמ"ה',
        noTutorials: 'לא נמצאו הדרכות קרובות',
        recommenders: 'ממליצים',
        gallery: 'גלרית תמונות'
    },
    en: {
        org_purchase: "Purchase Options for Organizations",
        upcoming_tutorials: "Upcoming Tutorials:",
        includes: "What does the system include?",
        download: "Click to download",
        goToWebsite: "Click to view the website",
        leaveDetails: "Leave details",
        signup: "Sign Up",
        developedBy: "The product was developed by",
        hilma: 'Hilma',
        noTutorials: 'No upcoming tutorials :(',
        recommenders: 'Recommenders',
        gallery: 'Photos gallery'

    }
})