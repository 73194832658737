import { createI18nText } from "../createTextFunctions";

export const DownloadTexts = createI18nText({
    he: {
        toDownload: 'להורדת האפליקציה',
        goToWebsite: 'לאתר',
        websiteText: 'הידעת? ניתן להתחבר ולהנות מ{name} גם באתר',
        createQrCode: 'יצירת קוד QR',
        qrCodePopupText: 'קוד QR - {name} ל{platform}'
    },
    en: {
        toDownload: 'Download the app',
        goToWebsite: 'Go to',
        websiteText: 'Did you know? You can connect and enjoy {name} on the site ',
        createQrCode: 'create QR code',
        qrCodePopupText: 'QR code - {name} for {platform}'
    }
})