import { SolutionsForYou } from "../components/home-page/SolutionsForYou";
import { useDirection } from "../translation/i18n";
import { NewsArticles } from "../components/home-page/NewsArticles";
import { OurSolutions } from "../components/home-page/OurSolutions";
import { Partners } from "../components/home-page/Partners";
import { Reviews } from "../components/home-page/Reviews";
import React from "react";

export const HomePage: React.FC = ({ }) => {
    const dir = useDirection();

    return <div className={"homepage-container " + dir}>
        <SolutionsForYou />
        <OurSolutions />
        <Reviews />
        <NewsArticles />
        <Partners />
    </div>
}