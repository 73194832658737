import { createI18nText } from "../createTextFunctions";

export const LoginTexts = createI18nText({
    he: {
        orSignInWith: 'או התחבר באמצעות',
        signInTitle: "כניסה לאזור אישי",
        redirectedTitle1: "לפני שממשיכים, נשמח להכיר",
        redirectedTitle2: "התחבר למערכת או צור חשבון חדש",
        signUpTitle: "הרשמה בתור משתמש חדש",
        name: "שם",
        username: "שם משתמש",
        password: "סיסמא",
        code: "קוד",
        verifyPassword: "אימות סיסמא",
        phone: "מספר טלפון",
        orgName: "שם הארגון",
        email: "כתובת מייל",
        fName: "שם פרטי",
        lName: "שם משפחה",
        signInBtn: "התחבר",
        signUpButton: "הירשם",
        next: "הבא",
        private: "משתמש פרטי",
        association: "ארגון",

        page0Top: "ברוך הבא להילמ\"ה",
        page1Top: "פרופיל אישי",
        page2Top: "איזה סוג משתמש אתה?",
        page3Top: "הגדרת סיסמה",
        pageDoneTop: "אימות חשבון במייל",
        pageGoogleDoneTop: "החשבון נוצר בהצלחה",

        page0Low: "צור חשבון מהיר ותהנה ממגוון מוצרים ופתרונות",
        page1Low: "נשמח להכיר קצת לפני שממשיכים",
        pageDoneLow: "החשבון נוצר בהצלחה! נשלחה הודעה אל חשבון המייל שהוכנס כדי לאמת אותו.",
        pageGoogleDoneLow: "כעת ניתן לרכוש וליהנות מהמוצרים שלנו.",
        haveAccount: "משתמש רשום? התחבר כאן",
        backToHome: "חזרה לעמוד הבית",
        noAccount: "אין לך חשבון? צור חשבון כאן",
        sendCodeBtn: "שלח קוד",
        homePage: "לעמוד הבית",
        resetPassword: 'איפוס סיסמא',
        forgotPassword: 'שכחתי סיסמא',
        backToLogin: 'חזרה להתחברות',
        sendResetPasswordSuccess: 'מייל לאתחול סיסמא נשלח אליך בהצלחה!',
        newPassword: 'סיסמא חדשה',
        confirmPassword: 'אימות סיסמא',
        resetPasswordSuccess: 'אתחול הסיסמא הושלם בהצלחה',
        AlreadyChanged: 'קישור זה כבר היה בשימוש, עליך לשלוח בקשה נוספת לשינוי הסיסמא'
    },
    en: {
        orSignInWith: 'or sign in with',
        signInTitle: "Sign in to your account",
        redirectedTitle1: "Before proceeding, we'd like to know you",
        redirectedTitle2: "Sign in or create a new account",
        signUpTitle: "Sign up as new user",
        name: "name",
        code: "Code",
        pWordVer: "Password confirmation",
        phone: "Phone number",
        orgName: "Organization name",
        email: "Email address",
        fName: "First name",
        lName: "Last name",
        uName: "Username",
        pWord: "Password",
        signInBtn: "Sign in",
        signUpButton: "Sign up",
        next: "Next",
        private: "Private User",
        association: "Organization",

        page0Top: "Welcome to Hilma",
        page1Top: "Profile",
        page2Top: "What type of user are you?",
        page3Top: "Set a password",
        pageDoneTop: "Account verification",
        pageGoogleDoneTop: "Account Created Successfully",

        page0Low: "Create a quick account an enjoy a variety of products and solutions",
        page1Low: "We'll be happy to get to know you a little bit before continuing",
        pageDoneLow: "Account created successfully! An email sent to your account in order to verify it.",
        pageGoogleDoneLow: "Now you can purchase and enjoy our products.",
        haveAccount: "Already registered? Log in here",
        backToHome: "Back to home page",
        noAccount: "No account? Register here",
        sendCodeBtn: "Send code",
        homePage: "To home page",
        resetPassword: 'Restore password',
        forgotPassword: 'Forgot password',
        backToLogin: 'Back to login',
        sendResetPasswordSuccess: 'Password reset email sent to you successfully!',
        newPassword: 'New password',
        confirmPassword: 'Confirm password',
        resetPasswordSuccess: 'Password reset completed successfully',
        AlreadyChanged: 'You already used this url to change your password, send another request'
    }
})